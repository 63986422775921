@import '/src/styles/mixin';
@import '/src/styles/variables';

.campaignDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.videoPlayer {
  width: 100%;
  background-color: $neutral-900 !important;
  backdrop-filter: blur(10px);
  @include aspect(9, 16);
}

.videoSpeaker {
  z-index: 3;
  position: absolute;
  top: 24px !important;
  right: 26px !important;

  svg {
    color: $neutral-white !important;
  }
}

.jobDescription {
  overflow-wrap: anywhere;
  font-family: $primaryFont;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $black-01;
  white-space-collapse: unset;

  h1, h2, h3, h4, h5, h6 {
    font-family: $primaryFont;
    font-size: unset;
    line-height: unset;
  }
  br, p:empty {
    display: none;
  }
}

.illustration {
  width: 100% !important;
  max-width: 192px !important;
  min-width: auto !important;
  height: 100% !important;
  max-height: 192px !important;
  min-height: auto !important;
  padding-bottom: 192px;
  margin: 0 auto;
}

@include mediaQueryMin(1024px) {
  .badge {
    min-height: 40px;
    min-width: 150px;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
    margin: 0 auto;
  }
}

@include mobile {
  .illustration {
    max-width: 140px !important;
    max-height: 140px !important;
    padding-bottom: 140px;
  }
}
