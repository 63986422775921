@import '/src/styles/variables';

.STContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background-color: $color-neutral-theme-50;
  border: 1px solid #DADBE7;

  box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.1);
  border-radius: 12px;
}

.STPromoLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-700;
}

.STPromoInput {
  height: 40px;
  margin-bottom: -32px;
  margin-top: 4px;

  input {
    height: 40px;
    padding: 0 0 0 16px !important;
  }

  >div {
    padding-right: 4px;
  }
}

.STPromoButton {
  height: 32px !important;
  width: 57px !important;
}

.STPromoInputError {
  font-size: 12px;
  line-height: 16px;
  color: $color-negative-500 !important;
}