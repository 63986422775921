@import '/src/styles/variables';
@import '/src/styles/mixin';

.resume {
  border: 1px solid $neutral-N500;

  &:hover {
    background-color: $neutral-100;
    border: 1px solid $grey-03
  }
}