@import '/src/styles/mixin';
@import '/src/styles/variables';

.InputSearch {
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  width: 12vw;

  &:hover {
    .InputSearchContainer {
      border-color: $color-neutral-theme-700;
    }
  }

  @media (max-width: 840px) {
    width: 100%;
    display: flex;
  }
}

.InputSearchContainer {
  height: 40px;

  background-color: $neutral-white;
  border: 1px solid $color-neutral-theme-200;
  border-radius: 12px;
  padding: 8px 12px;
  transition: all 0.2s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 840px) {
    width: 100%;
    flex: 1;
  }
}

.Input {
  width: 100%;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Inter';
}

.ButtonIcon {
  color: $color-white !important;
  background-color: $neutral-white !important;
  border: 1px solid $color-neutral-theme-200;
  min-width: unset !important;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 12px;

  &:hover {
    background-color: $neutral-white !important;
    border: 1px solid $color-neutral-theme-700;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.SearchWrapper {
  position: relative;

  @media (max-width: 840px) {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: row-reverse;
  }

  @media (max-width: 700px) {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
  }

  @media (max-width: 600px) {
    flex: 0;
  }

  &_isShowSearchBar {
    @media (max-width: 600px) {
      flex: 1;
    }
  }
}

.InputSearchBar {
  position: absolute;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  width: 203px;

  @media (max-width: 1200px) {
    width: 16vw;
    top: -20px;
    right: 0;
  }

  @media (max-width: 1170px) {
    width: 16vw;
    top: -20px;
    right: 0;
  }

  @media (max-width: 840px) {
    width: 15vw;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    flex: 1;
  }

  @media (max-width: 550px) {
    top: 0;
    left: 0;
    width: 100%;
  }

  @include onlyMobile() {
    top: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    .InputSearchContainer {
      border-color: $color-neutral-theme-700;
    }
  }
}

.disabledWrapper {
  opacity: 0.4000000059604645;
  pointer-events: none;
}