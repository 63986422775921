@import '/src/styles/variables';

.controlContainer {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  transition: opacity .3s ease-in-out;
  display: flex;
  align-items: flex-end;

  &.Mini {
    .control {
      padding: 0 8px;
      height: 8px;
    }
  }
}

.control {
  width: 100%;
  height: 36px;
  padding: 0 20px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  background: linear-gradient(0deg, #181920 5%, rgba(24, 25, 32, 0) 100%);

  display: flex;
  align-items: center;

  >div {
    width: 40px;
    color: $color-neutral-theme-50;
  }
}

.controlMini {
  background: none;
}
