@import '/src/styles/mixin';
@import '/src/styles/variables';

.image {
  width: 60px;
  border-radius: 8px;
}

.selected {
  @include box-shadow(0px 0px 8px 1px $neutral-white);
}


.mainImageContainer {
  .image {
    width: 300px;
    border-radius: 14px;
  }
}