@import 'mixin';

h1 {
  font-weight: 900;
  font-size: 96px;
  line-height: 110px;
  letter-spacing: -0.02em;
}

h2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  letter-spacing: -0.02em;
}

h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;

  &.mobile {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.48px;
  }
}

h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
}

.subtitle-regular {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.subtitle-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.subtitle--medium {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}

.body1-regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.body1-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.body1-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.body2-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.body2-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.body2-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.meta-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.meta-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.meta-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.tiny-semibold {
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
}

.font-medium {
  font-weight: 500;
}

$fontFamilies: (
  primary: $primaryFont,
  secondary: $secondaryFont,
);

@each $fontFamily, $value in $fontFamilies {
  .ff-#{$fontFamily} {
    font-family: $value;
  }
}

$fontSizes: (
  10: 1rem,
  12: 1.2rem,
  14: 1.4rem,
  16: 1.6rem,
  18: 1.8rem,
  20: 2.0rem,
  24: 2.4rem,
  28: 2.8rem,
  32: 3.2rem,
  36: 3.6rem,
  40: 4rem,
);

@each $fontSize, $value in $fontSizes {
  .fs-#{$fontSize} {
    font-size: $value;
    line-height: calc(#{$value} + 4px);
  }
}

$fontWeights: (
  thin: $thinWeight,
  extralight: $extraLightWeight,
  light: $lightWeight,
  regular: $regularWeight,
  medium: $mediumWeight,
  semibold: $semiBoldWeight,
  bold: $boldWeight,
  extrabold: $extraBoldWeight,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
);

@each $fontWeight, $value in $fontWeights {
  .fw-#{$fontWeight} {
    font-weight: $value;
  }

  .fw-#{$fontWeight}-p {
    font-weight: $value !important;
  }
}

$lineHeights: (
  tight: 12px,
  snug: 16px,
  normal: 20px,
  relaxed: 24px,
  loose: 28px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  28: 28px,
  32: 32px,
);

@each $lineHeight, $value in $lineHeights {
  .lh-#{$lineHeight} {
    line-height: $value;
  }
}

// latest typography
$font-sizes: (
  8: 10, // font-size: line-height
  10: 12,
  11: 14,
  12: 16,
  14: 18,
  16: 20,
  18: 24,
  20: 24,
  24: 28,
  28: 32,
  32: 40,
  36: 42,
  40: 48,
  48: 56,
  56: 64,
  60: 72,
  72: 86
);

$font-weights: (
  body: $regularWeight,
  heading: $mediumWeight,
  semibold: $semiBoldWeight,
  bold: $boldWeight,
);

@each $font-name, $font-weight in $font-weights {
  @each $font-size, $line-height in $font-sizes {
    .#{$font-name}-#{$font-size} {
      @include typo($font-size * 1rem / 10, $font-weight, $line-height * 1rem / 10);
    }
  }
}

// deprecated typography
h1,
.heading-1 {
  font-size: 9.6rem;
  line-height: 11.6rem;
  font-weight: $boldWeight;

  &.mobile {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
}

h2,
.heading-2 {
  font-size: 6.4rem;
  line-height: 7.7rem;
  font-weight: $boldWeight;

  &.mobile {
    font-size: 4.0rem;
    line-height: 4.8rem;
  }
}

h3,
.heading-3 {
  font-size: 4.0rem;
  line-height: 4.8rem;
  font-weight: $boldWeight;

  &.mobile {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}

h4,
.heading-4 {
  font-size: 3.2rem;
  line-height: 3.9rem;
  font-weight: $boldWeight;

  &.mobile {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
}

h5,
.heading-5 {
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: $boldWeight;

  &.mobile {
    font-size: 2.0rem;
    line-height: 2.4rem;
    margin-bottom: 0;
  }
}

.subtitle {
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.body-1 {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.body-2 {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.meta {
  font-size: 1.2rem;
  line-height: 2.0rem;
}