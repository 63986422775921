@import '/src/styles/variables';
@import '/src/styles/typography';

.wrapper {
  position: relative;
  z-index: 0;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 999rem;
  color: #FFF;
  background: linear-gradient(90deg, #7EF2A7 0%, #9EF291 24.5%, #C2F213 59%, #CCF241 75%, #D4D94A 100%);
  cursor: pointer;
}

.menu {
  width: 222px;
  position: absolute;
  z-index: 999;
  top: calc(100% + 24px);
  right: 0;
  padding: 8px 0;
  border-radius: 12px;
  background-color: $neutral-white;
  border: 1px solid $neutral-N500;
}

.STUMDropdownMinimized {
  bottom: 40px;
  left: 100px;
}

.STInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 12px; // ${theme.spacing(1.5)}
}

.item {
  width: 100%;
  cursor: pointer;
  padding: 12px 20px;

  display: flex;
  align-items: center;
  gap: 12px;
  color: $black-02;

  @extend .body2-medium;
  font-weight: 500;

  span {
    line-height: 20px;
  }

  &:hover {
    background: $green-500;
  }
}
