@import '/src/styles/variables';

.stepIndicator {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.step {
  flex: 1;
  width: 100%;
  background-color: $color-primary-600;
  height: 3px;
}

.stepFilled {
  background-color: $color-neutral-theme-900;
}