@import '/src/styles/variables';

.container {
  background: rgba(24, 25, 32, 0.70);

  .iconButton {
    visibility: visible;
    transition: visibility 0.3s ease-in-out;
  }

  transition: all 0.3s ease-in-out;
}

.iconButton {
  width: 32px;
  height: 32px;
  background-color: $color-primary-500 !important;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;

  &:hover {
    background-color: $color-primary-600 !important;
  }
}
