@import '/src/styles/mixin';
@import '/src/styles/variables';

.STContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: auto;
  border-radius: 32px;
  padding: 32px 48px;
  background-color: $color-neutral-theme-50;

  @include mobile() {
    width: 100%;
    padding: 48px 24px;
  }
}

.STHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__Title {
      font-size: 24px;
      font-weight: 500;
      color: $color-neutral-theme-700;
      font-style: normal;
      line-height: 48px;
      text-align: center;
      letter-spacing: -0.02em;
    }
}

.STBody {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.STForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 393px;

  @include mobile() {
    width: 339px;
    min-width: unset;
  }
}

.ViewOption {
  width: 100%;

  @media (max-width: 840px) {
    width: 100%;
  }

  @media screen and (max-width: 905px) and (min-width: 840px) {
    width: 145px;
  }

  &_isShowSearchBar {
    @media (max-width: 600px) {
      display: none;
    }
  }
}