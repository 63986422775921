@import '/src/styles/mixin';
@import '/src/styles/variables';

.teleprompterContainer {
  overflow: hidden;
  padding: 24px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: -0.02em;
  word-wrap: break-word;
  white-space: pre-line;
  height: 185px;
  margin: 0 auto 4px;
  width: 100%;

  @include mobile() {
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-neutral-theme-200;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-neutral-theme-300;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  position: absolute;
  top: calc(100% + 12px);
  right: 12px;
}

.buttonIcon {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 2px 12px;
  gap: 8px;

  height: 32px;

  background: rgba(24, 25, 32, 0.70);
  border-radius: 10px;
  font-family: Inter;

  font-weight: 700;
  font-size: 16px;
  outline: none;
  border: none;
  color: $color-white;
  justify-content: center;
}