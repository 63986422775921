@import '/src/styles/mixin';
@import '/src/styles/variables';

.containerGuest {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  overflow: auto;

  @include onlyMobile() {
    gap: 16px;
    padding: 12px 24px;
  }
}

.tab {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 20px;
  background-color: $color-white;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 6px rgba(161, 161, 161, 0.1);
}

.header_tab {
  display: flex;
  flex-direction: row;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include mediaQueryMax(1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mediaQueryMax(1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mediaQueryMax(1153px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax(842px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax(610px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.empty {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.height100 {
  height: 100%;
}

.headerMyJob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;

  .title {
    font-size: 28px;
    font-weight: 600;
    color: $color-neutral-theme-800;
  }

  .count {
    font-size: 20px;
    font-weight: 400;
    color: $neutral-N100
  }
}