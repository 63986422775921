@import '/src/styles/mixin';
@import '/src/styles/variables';

.vibes {
  overflow: hidden;
  padding: 20px 0 40px;

  @include onlyMobile() {
    padding: 0;
  }
}

.vibesGuest {
  overflow: hidden;
  padding: 0;
  position: relative;
}

.videoPlayer {
  @include onlyMobile() {
    width: 100% !important;
    height: 100% !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.vibesWrapper {
  display: flex;
  flex-direction: column;
  background: $color-neutral-theme-50;
  border-radius: 16px;

  @include onlyMobile() {
    aspect-ratio: unset !important;
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.vibesWrapperGuest {
  @extend .vibesWrapper;

  @include onlyMobile() {
    height: calc(100vh - 68px) !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.bottomBar {
  @include onlyMobile() {
    height: 0;
    flex: 0;
  }
}

.icons {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%, -100%);

  @include onlyMobile() {
    flex-direction: column;
    left: unset;
    right: 0;
  }

  .icon {
    border-radius: 100%;
    background-color: $color-neutral-theme-50;
  }
}

.traits {
  @include onlyMobile() {
    flex-direction: column;
    gap: 8px;
    align-items: start !important;
    position: fixed;
    left: 0;
    z-index: 2;
    background: transparent !important;
    top: 85px;
    span {
      color: $color-neutral-theme-900;
    }
  }
}

.bannerClassName {
  top: 76px !important;

  @include onlyMobile() {
    width: fit-content !important;
    position: absolute !important;
    left: auto !important;
    right: 24px !important;
  }
}