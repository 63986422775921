@import 'variables';
@import 'mixin';

$border-radius-values: (
  none: 0,
  sm: 0.125rem,
  md: 0.375rem,
  lg: 0.5rem,
  xl: 0.75rem,
  '2xl': 1rem,
  full: 9999px
);

.rounded {
  @each $key, $value in $border-radius-values {
    &-#{$key} {
      border-radius: $value;
    }
  }
}