@import '/src/styles/variables';
@import '/src/styles/mixin';

.modalInviteJob {
  max-width: 440px;
  width: 440px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}