@import '/src/styles/variables';

.FlipCardBackContainer {
  width: 100%;
  height: 100%;
  z-index: 2;
  aspect-ratio: 343/601;
}

.FlipCardBackContent {
  padding: 12px 16px;
  max-height: calc(100% + 88px);
  overflow: auto;
  background-color: #fff;
  border-radius: 16px;
  height: 100%;
}

.STCardSummaryHeader {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-neutral-theme-50;
  position: absolute;
  width: 100%;
  left: 0;
  top: 14px;
  padding: 0 16px;
  z-index: 2;
}

.STCardSummaryGeneral {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 14px;

  &_Section {
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: center;

    svg {
      flex-shrink: 0;
    }
  }
}

.headerBackButton {
  margin-left: -8px;
}

.summaryContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 100px);
}

.cardSummaryFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $color-neutral-theme-200;
  background-color: #fff;
}

.iconButtonDetail {
  width: 32px;
  height: 32px;
  background-color: $color-primary-500 !important;

  &:hover {
    background-color: $color-primary-400 !important;
  }
}

.illustrationStar {
  position: absolute;
  top: 24px;
  right: 56px;
}

.illustrationComment {
  position: absolute;
  top: 14px;
  right: 14px;
}
