.overlay {
  :global {
    .MuiDialog-scrollPaper {
      height: 100vh;
      height: var(--window-height);
      display: block;
      overflow: auto;
      align-items: center;
    }

    .MuiDialog-paperScrollPaper {
      margin: 0;
      height: 100vh;
      height: var(--window-height);
      overflow: unset;
      align-items: center;
    }

    .MuiDialog-paper {
      max-width: unset;
      max-height: unset;
      background: unset;
      transition: unset;
      box-shadow: unset;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .MuiBackdrop-root {
      background-color: transparent;
    }
  }
}

.hasContent {
  background: rgba(36 37 45 / 40%);

  :global {
    .MuiBackdrop-root {
      background-color: '';
    }
  }
}

.closeIcon {
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 2;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgb(37 38 46 / 10%);
  border-radius: 32px;
  background-color: var(--color-neutral-theme-50);
}