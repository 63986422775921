@import '/src/styles/mixin';
@import '/src/styles/variables';

.tabsContainer {
  display: flex;
  gap: 40px;
  padding: 0 20px;
  background: var(--color-neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.tabItem {
  color: var(--color-neutral-N400);
  border-bottom: 5px solid transparent;
  @include transition(color $transition-duration ease-in-out, border-color $transition-duration ease-in-out);

}

.tabActive {
  color: var(--color-neutral-800);
  border-color: $color-positive-400;
  font-weight: 600;
}