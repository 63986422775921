@import '/src/styles/mixin';
@import '/src/styles/variables';

.reactionCard {
  max-width: 550px;
  width: 100%;
  background-color: $neutral-white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 16px 16px 22px;

  display: flex;
  justify-content: space-between;
  gap: 16px;
}