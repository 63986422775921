@import '/src/styles/variables';

.STContainer {
  max-height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 25px;
  gap: 32px;
  background-color: $color-neutral-theme-100;
}
