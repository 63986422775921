@import '/src/styles/mixin';
@import '/src/styles/variables';

.badge {
  position: absolute;
  top: -6px;
  right: -6px;
  height: 18px;
  min-width: 18px;
  padding: 0 4px;

  font-family: Inter;
  font-size: 12px;
  line-height: 1;
  font-weight: $mediumWeight;
}
