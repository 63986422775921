@import '/src/styles/mixin';
@import '/src/styles/variables';

.profileBlock {
  width: min(600px, 100%);
  position: relative;
}

.pencil {
  position: absolute;
  top: 24px;
  right: 24px;
}