@import 'size';

/**
 * Font
 */
$primaryFont: Inter;
$secondaryFont: Walter Turncoat;
$thinWeight: 100;
$extraLightWeight: 200;
$lightWeight: 300;
$regularWeight: 400;
$mediumWeight: 500;
$semiBoldWeight: 600;
$boldWeight: 700;
$extraBoldWeight: 800;

$color-white: #fff;
$color-black: #000;

$primary-400: #F7FB6F;
$color-primary-50: #FEFFF5;
$color-primary-100: #FEFEEC;
$color-primary-200: #FCFECD;
$color-primary-300: #FAFDAA;
$color-primary-400: #F7FB6F;
$color-primary-500: #F2F92F;
$color-primary-600: #DAE029;
$color-primary-700: #B7BC24;
$color-primary-800: #92961D;
$color-primary-900: #757817;

$color-blue-50: #f3f4fc;
$color-blue-500: #0099FF;

// blue
$blue-400: #0099FF;
$blue-1000: #0066AD;

$color-positive-50: #F6FDFA;
$color-positive-100: #EAFBF2;
$color-positive-200: #D0F6E3;
$color-positive-300: #B2F0D1;
$color-positive-400: #78E59E;
$color-positive-500: #3AD98A;
$color-positive-600: #32C37A;
$color-positive-700: #2BA166;
$color-positive-800: #218253;
$color-positive-900: #1C6944;

$color-negative-50: #FEF6F7;
$color-negative-100: #FDE8EB;
$color-negative-200: #FACCD4;
$color-negative-300: #F7ABB8;
$color-negative-400: #F26E84;
$color-negative-500: #EA2E4E;
$color-negative-600: #D52A47;
$color-negative-700: #AF233A;
$color-negative-800: #8D1C2F;
$color-negative-900: #731726;

$color-orange-50: #FFF8F0;
$color-orange-100: #FFF3E6;
$color-orange-200: #FFE0BD;
$color-orange-300: #FFCF99;
$color-orange-400: #FFAC4D;
$color-orange-500: #FF8800;
$color-orange-600: #E67A00;
$color-orange-700: #BD6500;
$color-orange-800: #995200;
$color-orange-900: #804400;

$color-green-50: #F9FEE7;
$color-green-75: #E6FA9E;
$color-green-100: #DCF776;
$color-green-200: #CCF43B;
$color-green-300: #C2F213;
$color-green-400: #AFDA11;

$color-cyan-50: #F2FDFD;
$color-cyan-100: #E8FBFC;
$color-cyan-200: #BFF3F7;
$color-cyan-300: #97EBF2;
$color-cyan-400: #4FDEE8;
$color-cyan-500: #00D1E0;
$color-cyan-600: #00BAC7;
$color-cyan-700: #009DA8;
$color-cyan-800: #007C85;
$color-cyan-900: #00646B;

$color-purple-50: #F7F3FC;
$color-purple-100: #F0E5FA;
$color-purple-200: #DAC0F2;
$color-purple-300: #C299EB;
$color-purple-400: #944DDB;
$color-purple-500: #6600CC;
$color-purple-600: #5C00B8;
$color-purple-700: #4D0099;
$color-purple-800: #3D007A;
$color-purple-900: #330066;

$color-neutral-theme-50: #FFFFFF;
$color-neutral-theme-100: #F1F2F3;
$color-neutral-theme-200: #DADBE7;
$color-neutral-theme-250: #AFB0BE;
$color-neutral-theme-300: #80818E;
$color-neutral-theme-400: #5B5C67;
$color-neutral-theme-500: #42434D;
$color-neutral-theme-600: #2C2D35;
$color-neutral-theme-700: #24252D;
$color-neutral-theme-800: #111111;
$color-neutral-theme-900: #181920;

// neutral
$neutral-black: #000000;
$neutral-100: #F1F2F3;
$neutral-N100: #595959;
$neutral-200: #DADBE7;
$neutral-250: #AFB0BE;
$neutral-300: #80818E;
$neutral-N300: #EFEFEF;
$neutral-400: #5B5C67;
$neutral-500: #42434D;
$neutral-600: #2C2D35;
$neutral-700: #24252D;
$neutral-800: #1D1E26;
$neutral-900: #181920;
$neutral-white: #FFFFFF;

// neutral
$neutral-10: #F1F2F3;
$neutral-20: #F7F7F4;
$neutral-30: #E6E6E6;
// $neutral-30: #A4A4A4;
$neutral-40: #CCCCCC;
$neutral-50: #909090;
$neutral-N50: #909090;
$neutral-N100: #595959;
$neutral-100: #F8F8F8;
$neutral-200: #DADBE7;
$neutral-250: #AFB0BE;
$neutral-270: #959595;
$neutral-300: #80818E;
$neutral-N300: #474747;
$neutral-N400: #434343;
$neutral-N500: #D3D3D3;
$neutral-400: #5B5C67;
$neutral-500: #42434D;
$neutral-600: #282828;
$neutral-700: #24252D;
$neutral-700-04: rgba(36, 37, 45, 0.40);
$neutral-750: #242424;
$neutral-800: #111111;
$neutral-900: #181920;
$neutral-900-04: rgba(24, 25, 32, 0.40);
$neutral-900-07: rgba(24, 25, 32, 0.70);
$neutral-900-08: rgba(24, 25, 32, 0.80);
$neutral-black: #000000;
$neutral-black-02: rgba(0, 0, 0, 0.2);
$neutral-black-025: rgba(0, 0, 0, 0.25);
$neutral-white: #FFFFFF;
$neutral-white-02: #EFEFEF;

// green
$green-transparent-text: #F9FEE799;
$green-40: #F8FFEC;
$green-50: #F9FEE7;
$green-75: #E6FA9E;
$green-100: #DCF776;
$green-200: #CCF43B;
$green-g300: #DCFF8F;
$green-300: #C2F213;
$green-400: #AFDA11;
$green-500: #F0FFD4;
$green-600: #57A974;

// turquoise
$turquoise-300: #7EF2A7;
$turquoise-400: #71DA96;

// black
$black-01: #0C0C0C;
$black-02: #303030;
$black-03: #081021;

// grey
$grey-01: #636363;
$grey-02: #E2E2E2;
$grey-03: #959595;

// red
$red-01: #F13C13;
$red-02: #FB5230;

// negative
$negative-50: #FEF6F7;
$negative-100: #FDE8EB;
$negative-200: #FACCD4;
$negative-300: #F7ABB8;
$negative-400: #F26E84;
$negative-500: #EA2E4E;
$negative-600: #E1282B;
$negative-700: #AF233A;
$negative-800: #8D1C2F;
$negative-900: #731726;

$colors: (
  color-white: $color-white,
  color-black: $color-black,
  primary-400: $primary-400,
  color-primary-50: $color-primary-50,
  color-primary-100: $color-primary-100,
  color-primary-200: $color-primary-200,
  color-primary-300: $color-primary-300,
  color-primary-400: $color-primary-400,
  color-primary-500: $color-primary-500,
  color-primary-600: $color-primary-600,
  color-primary-700: $color-primary-700,
  color-primary-800: $color-primary-800,
  color-primary-900: $color-primary-900,
  color-positive-50: $color-positive-50,
  color-positive-100: $color-positive-100,
  color-positive-200: $color-positive-200,
  color-positive-300: $color-positive-300,
  color-positive-400: $color-positive-400,
  color-positive-500: $color-positive-500,
  color-positive-600: $color-positive-600,
  color-positive-700: $color-positive-700,
  color-positive-800: $color-positive-800,
  color-positive-900: $color-positive-900,
  color-negative-50: $color-negative-50,
  color-negative-100: $color-negative-100,
  color-negative-200: $color-negative-200,
  color-negative-300: $color-negative-300,
  color-negative-400: $color-negative-400,
  color-negative-500: $color-negative-500,
  color-negative-600: $color-negative-600,
  color-negative-700: $color-negative-700,
  color-negative-800: $color-negative-800,
  color-negative-900: $color-negative-900,
  color-orange-50: $color-orange-50,
  color-orange-100: $color-orange-100,
  color-orange-200: $color-orange-200,
  color-orange-300: $color-orange-300,
  color-orange-400: $color-orange-400,
  color-orange-500: $color-orange-500,
  color-orange-600: $color-orange-600,
  color-orange-700: $color-orange-700,
  color-orange-800: $color-orange-800,
  color-orange-900: $color-orange-900,

  color-green-50: $color-green-50,
  color-green-75: $color-green-75,
  color-green-100: $color-green-100,
  color-green-200: $color-green-200,
  color-green-300: $color-green-300,
  color-green-400: $color-green-400,

  green-40: $green-40,
  green-50: $green-50,
  green-75: $green-75,
  green-100: $green-100,
  green-200: $green-200,
  green-g300: $green-g300,
  green-300: $green-300,
  green-400: $green-400,
  green-500: $green-500,
  green-600: $green-600,

  color-blue-50: $color-blue-50,
  color-blue-500: $color-blue-500,

  blue-400: $blue-400,
  blue-1000: $blue-1000,

  color-cyan-50: $color-cyan-50,
  color-cyan-100: $color-cyan-100,
  color-cyan-200: $color-cyan-200,
  color-cyan-300: $color-cyan-300,
  color-cyan-400: $color-cyan-400,
  color-cyan-500: $color-cyan-500,
  color-cyan-600: $color-cyan-600,
  color-cyan-700: $color-cyan-700,
  color-cyan-800: $color-cyan-800,
  color-cyan-900: $color-cyan-900,
  color-purple-50: $color-purple-50,
  color-purple-100: $color-purple-100,
  color-purple-200: $color-purple-200,
  color-purple-300: $color-purple-300,
  color-purple-400: $color-purple-400,
  color-purple-500: $color-purple-500,
  color-purple-600: $color-purple-600,
  color-purple-700: $color-purple-700,
  color-purple-800: $color-purple-800,
  color-purple-900: $color-purple-900,
  color-neutral-theme-50: $color-neutral-theme-50,
  color-neutral-theme-100: $color-neutral-theme-100,
  color-neutral-theme-200: $color-neutral-theme-200,
  color-neutral-theme-250: $color-neutral-theme-250,
  color-neutral-theme-300: $color-neutral-theme-300,
  color-neutral-theme-400: $color-neutral-theme-400,
  color-neutral-theme-500: $color-neutral-theme-500,
  color-neutral-theme-600: $color-neutral-theme-600,
  color-neutral-theme-700: $color-neutral-theme-700,
  color-neutral-theme-800: $color-neutral-theme-800,
  color-neutral-theme-900: $color-neutral-theme-900,
  neutral-black: $neutral-black,
  neutral-10: $neutral-10,
  neutral-20: $neutral-20,
  neutral-30: $neutral-30,
  neutral-100: $neutral-100,
  neutral-N100: $neutral-N100,
  neutral-200: $neutral-200,
  neutral-250: $neutral-250,
  neutral-300: $neutral-300,
  neutral-400: $neutral-400,
  neutral-500: $neutral-500,
  neutral-N500: $neutral-N500,
  neutral-600: $neutral-600,
  neutral-700: $neutral-700,
  neutral-750: $neutral-750,
  neutral-800: $neutral-800,
  neutral-900: $neutral-900,
  neutral-white: $neutral-white,
  neutral-N300: $neutral-N300,

  black-01: $black-01,
  black-02: $black-02,
  black-03: $black-03,

  grey-01: $grey-01,
  grey-02: $grey-02,
  grey-03: $grey-03,

  red-01: $red-01,
  red-02: $red-02,

  negative-50: $negative-50,
  negative-100: $negative-100,
  negative-200: $negative-200,
  negative-300: $negative-300,
  negative-400: $negative-400,
  negative-500: $negative-500,
  negative-600: $negative-600,
  negative-700: $negative-700,
  negative-800: $negative-800,
  negative-900: $negative-900,
);

$transition-duration: 0.3s;
// $box-shadow-focused-input: 1px 1px 3px 0px $cyan-400, -1px -1px 3px 0px $cyan-400;
$box-shadow-modal-light: rgba(37, 38, 46, 0.2) 0px 8px 16px;