@import '/src/styles/mixin';
@import '/src/styles/variables';

.more {
  background-color: $color-neutral-theme-50;
  color: $color-cyan-600;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-cyan-500;
  }
}

.drawer {
  [class*='MuiDrawer-paper'] {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }
}

.drawerDescription {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 35px);
  gap: 24px;

  background-color: $color-neutral-theme-50;
  border-radius: 40px 0px 0px 40px;
  height: 100%;

  @include onlyMobile() {
    width: 100%;
  }
}

.aboutTheJob {
  padding: 40px 60px 0;

  @include onlyMobile() {
    padding: 24px 16px 0;
  }
}

.description {
  overflow: auto;
  padding-left: 60px;
  padding-right: 60px;

  @include onlyMobile() {
    padding: 0 16px;
  }
}

.buttonCloseDrawer {
  position: absolute;
  top: 50%;
  left: -35px;
  transform: translate(0%, -50%);
  cursor: pointer;

  @include onlyMobile() {
    display: none;
  }
}

.close {
  position: absolute !important;
  right: 24px;
  top: 22px;
  display: none !important;

  @include onlyMobile() {
    display: block !important;
  }
}

.pdfDescBtn {
  border: 0;
  outline: none;
  background-color: transparent;
}