@import '/src/styles/mixin';
@import '/src/styles/variables';

.inputPhoneNumber {
  .container {
    transition: all $transition-duration ease-in-out;

    &.primary {
      @include border($neutral-100);

      &:hover {
        @include border($neutral-200);
      }

      &.focus {
        @include border($neutral-300);
      }
    }

    &.secondary {
      @include border($neutral-200);

      &:hover {
        @include border($neutral-300);
      }

      &.focus {
        @include border($neutral-400);
      }
    }

    &.regular {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    &.error {
      @include border($negative-500 !important);
    }

    &.disabled {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .main {
    transition: all $transition-duration ease-in-out;

    &.primary {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 40px $neutral-100 inset;
      }

      &:hover {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 40px $neutral-100 inset;
        }
      }

      &:focus {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 40px $neutral-100 inset;
        }
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: $neutral-700;
      }
    }

    &.primary,
    &.secondary {
      &::placeholder {
        color: $neutral-300;
      }
    }

    &.small {
      &::placeholder {
        font-size: 14px;
      }
    }

    &.regular {
      &::placeholder {
        font-size: 16px;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }

    /**
    * Remove up/down arrows from number input
    */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}