@import '/src/styles/variables';

.container {
  border-radius: 16px;
  border: 1px solid $color-purple-200;
  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.header {
  background-color: $color-purple-100;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 12px 16px;

  gap: 16px;
}

.headerTitleGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.headerTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: $color-purple-400;
}

.headerSubTitle {
  color: $color-purple-400;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.body {
  display: flex;
  flex: 1;

  padding: 14px 16px;
}

.bodyPre {
  color: $color-neutral-theme-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border: 1px solid transparent;
  padding: 2px 8px;
  outline: none;
  border-radius: 10px;
  word-break: break-all;

  &:hover {
    border: 1px solid $color-neutral-theme-100;
  }

  &:focus {
    border: 1px solid $color-neutral-theme-200;
  }
}

.savedSummary {
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  text-align: right;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}