@import '/src/styles/variables';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 301px;
  padding: 32px 24px;
}

.description {
  color: $color-neutral-theme-300;
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
  font-weight: 400;
}

.myButton {
  width: 100%;
  height: 40px;
  outline: 1px solid $color-neutral-theme-200;
  box-shadow: none;
}