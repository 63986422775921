@import '/src/styles/variables';

.STMenu {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 24px;
  margin-top: 4px !important;

  position: relative;
  width: 194px;

  /* Neutral/White */

  background: #FFFFFF;
  /* Neutral/200 */

  border: 1px solid #DADBE7;
  /* Elevation/04 */

  box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.1);
  border-radius: 8px;

  ul {
    padding-inline-start: 0;
    margin: 0;
    gap: 24px;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-block: 0;

    li {
      cursor: pointer;

      :hover {
        color: #24252D;
      }
    }
  }
}

.STButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  z-index: 9;

  height: 40px;

  border-radius: 8px!important;

  /* Inside auto layout */
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $color-neutral-theme-700;
  background-color: $color-neutral-theme-100 !important;
  flex: none;
  flex-grow: 0;
}
