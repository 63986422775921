@import '/src/styles/variables';

.buttonLikeCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background-color: $color-neutral-theme-200;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
  flex-shrink: 0;
}

.large {
  width: 32px;
  height: 32px ;
}
