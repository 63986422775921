@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  padding: 32px;
  width: 100%;
  height: 100%;

  display: grid;

  gap: 16px;

  @include onlyMobile() {
    padding: 8px;
  }

  grid-auto-rows: min-content;

  grid-template-columns: repeat(2, 1fr);

  @media only screen and (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 900px) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
