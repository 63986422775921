@import 'variables';

@each $color, $value in $colors {
  .svg-#{$color} {
    path {
      fill: $value;
    }
  }

  .#{$color}, .txt-#{$color} {
    color: $value;
  }

  .txt-#{$color}-p {
    color: $value !important;
  }

  .hover-#{$color}:hover, .hover-txt-#{$color}:hover {
    color: $value;
  }

  .hover-txt-#{$color}-p:hover {
    color: $value !important;
  }

  .hover-bg-#{$color}:hover {
    background-color: $value;
  }

  .hover-bg-#{$color}-p:hover {
    background-color: $value !important;
  }

  .bg-#{$color} {
    background-color: $value;
  }

  .bg-#{$color}-p {
    background-color: $value !important;
  }

  .border-#{$color} {
    border-color: $value !important;
  }

  // TODO: delete this old code
  // .#{$color} {
  //   color: $value;
  // }

  // .hover-svg-#{$color}:hover {
  //   path {
  //     fill: $value;
  //   }
  // }

  // .group {
  //   &:hover {
  //     .group-hover-#{$color} {
  //       color: $value;
  //     }

  //     .group-hover-bg-#{$color} {
  //       background-color: $value;
  //     }

  //     .group-hover-svg-#{$color} {
  //       path {
  //         fill: $value;
  //       }
  //     }
  //   }
  // }
}
