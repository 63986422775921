@import '/src/styles/variables';

.FilterDropdownBtn {
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  width: 15vw;

  &:hover {
    .FilterDropdownBtnContainer {
      border-color: $color-neutral-theme-700;
    }
  }
}

.FilterDropdownBtnContainer {
  height: 40px;
  background-color: $neutral-white;
  border: 1px solid $color-neutral-theme-200;
  border-radius: 12px;
  padding: 8px 12px;
  transition: all 0.2s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

}

.FilterDropdownBtnLabel {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-700;
  margin: 0;
  font-weight: 500;
  margin-right: 12px;
}

.Menu {
  border-radius: 12px;
  margin-top: 8px;
}

.MenuItem {
  padding: 8px !important;
  display: flex;
  justify-content: space-between !important;
  gap: 4px;
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  border-radius: 12px !important;

  &:hover {
    background-color: $color-neutral-theme-100 !important;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.disableWrapper {
  opacity: 0.4000000059604645;
  pointer-events: none;
}