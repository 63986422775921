@import '/src/styles/mixin';
@import '/src/styles/variables';

.header {
  display: flex;
  align-items: center;
  padding: 4px 32px;
  position: relative;
  height: 68px;
  min-height: 68px;
  z-index: 3;
  background-color: #FFF;
  @include box-shadow(inset 0px 0px 0px 1px $neutral-100);
}
