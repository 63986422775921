.recorderVideo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-size: min(14px, 1.4rem);
  line-height: min(24px, 2.4rem);
  position: relative;
  video {
    min-width: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;

    // TODO: just for testing
    // object-fit: contain !important;
  }
}

// .iconContainer {
//   cursor: pointer;
//   margin: 0 auto 24px;
//   position: relative;
// }

// .counter {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }