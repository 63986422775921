@import '/src/styles/mixin';
@import '/src/styles/variables';

.conversationCard {
  width: 100%;
  max-width: 500px;
  background-color: $neutral-white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 16px 16px 22px;

  display: flex;
  justify-content: space-between;
  gap: 16px;

  &.activate {
    border: 1px solid $grey-03;
  }
}

.iconChat path {
  fill: $neutral-white;
}