@import '/src/styles/variables';

.STContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 34px;

  img {
    width: 235px;
    height: 317px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    h5,
    span {
      max-width: 472px;
      text-align: center;
    }

    .vibeBtn {
      margin-top: 24px;
      padding: 16px 32px;
    }
  }
}