@import '/src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 320px;
    height: 320px;
    object-fit: cover;
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $color-neutral-theme-700;
  }
}