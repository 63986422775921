@import '/src/styles/variables';

.STEmailInputContainer {
  min-height: 56px;
  overflow: hidden;
  background-color: $color-neutral-theme-100;
  border-radius: 16px;
  padding-right: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  cursor: text;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 1px $color-neutral-theme-200;
  }
}

.STTag {
  height: 32px;
  padding: 0 8px;
  border-radius: 25px;
  background-color: $color-neutral-theme-200;
  cursor: grab;
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 4px;
}

.STClose {
  padding: 4px;
  background-color: $color-neutral-theme-250;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  svg {
    width: 8px;
    height: 8px;

    path: {
      fill: $color-neutral-theme-700;
    }
  }
}

.InputWrapper {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 42px;
  display: flex;
  overflow-x: auto;
  gap: 4px;
  flex-wrap: nowrap;
  align-items: center;
  overflow-y: hidden;
  flex-wrap: wrap;

&::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
&::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
&::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
&::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
&::-webkit-scrollbar-thumb:active {
  background: $color-neutral-theme-100;
}
&::-webkit-scrollbar-track {
  background: $color-neutral-theme-100;
  border: 0px none #ffffff;
  border-radius: 50px;
}
&::-webkit-scrollbar-track:hover {
  background: $color-neutral-theme-100;
}
&::-webkit-scrollbar-track:active {
  background: $color-neutral-theme-100;
}
&::-webkit-scrollbar-corner {
  background: transparent;
}
}

.Icon {
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.Input {
  border: none;
  outline: none;
  background-color: transparent;
  caret-color: #00D1E0;
  min-width: 1px;
  white-space: nowrap;
  overflow: hidden;

  // these trick to make the Input clickable in IOS safari, see component onFocus handler and onBlur handler
  position: absolute;
  left: 30px;
  width: 100%;
  top: 0;
  height: 100%;

  br {
    display:none;
  }

  * {
    display:inline;
    white-space:nowrap;
  }
}

.FakeInputPlaceholder {
  color: $color-neutral-theme-300;
}

.Domain {
}
