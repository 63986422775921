@import 'variables';
@import 'mixin';

.RecordTabs {
  min-height: auto !important;

  .Mui-selected {
    background-color: transparent;
    color: $color-neutral-theme-900 !important;
    opacity: 1;

    @include mediaQueryMax(769px) {
      color: $color-white !important;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-cyan-500;
    height: 4px;
    border-radius: 2px;
    width: 27px !important;
    margin: 0 24px !important;
    margin-bottom: 4px;
  }

  @include mediaQueryMax(769px) {
    margin-bottom: 8px;
  }
}

.SampleCarousel {
  .swiper-slide-prev {
    transform: translate(0, -7.5%) scale(0.5) !important;
  }

  .swiper-slide-next {
    transform: translate(0, -7.5%) scale(0.5) !important;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}

.customSwiperTutorial {
  .swiper-slide {
    height: auto;
  }
}