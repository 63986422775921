@import '/src/styles/variables';
@import '/src/styles/mixin';

.message {
  margin: 0;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  color: $black-01;
  line-height: 20px;
  word-break: break-word;
  text-overflow: ellipsis;

  p {
    margin: 0;
  }

  &.attachments {
    padding: 0;
    background-color: unset;
    border-radius: unset;
  }
}