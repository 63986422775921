@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  padding: 12px 32px;
  display: flex;
  flex-direction: column;
  gap: 29px;

  @include onlyMobile() {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $color-white;
  flex: 1;
  overflow: auto;
  padding-top: 16px;
}

.content_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content_action {
  position: sticky;
  bottom: 0;
  z-index: 888;
}
