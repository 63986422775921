@import '/src/styles/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: center;
  flex-wrap: wrap;
}

.owners {
  line-height: 0px;
}