@import '/src/styles/variables';
@import '/src/styles/mixin';

.dropdown {
  width: 160px;
  margin-top: 8px;
  border: 1px solid $neutral-N500;
  border-radius: 12px;
  padding: 8px 0;
  background-color: $neutral-white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  div:hover {
    background-color: $green-500;
  }
}