@import '/src/styles/mixin';
@import '/src/styles/variables';

.recorderAudio {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  height: 100%;
  background-color: $color-black;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  canvas {
    width: 100%;
    opacity: .8;
    height: 100px;
  }

  @include mediaQueryMax(768px) {
    border-radius: 0;
  }
}

.iconContainer {
  cursor: pointer;
  margin: 0 auto 20px;
  position: relative;
}

.counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 4px;
  font-size: 14px;
  color: $color-white;
}

.RecordTime {
  position: absolute;
  top: 36px;
  right: 36px;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  gap: 8px;

  .Dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;

    background-color: $color-negative-500;
  }
}