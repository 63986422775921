@import '/src/styles/variables';
@import '/src/styles/mixin';

.modalTalentDetail {
  max-width: 1440px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: $neutral-white;
}

.videoWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contactUser {
  width: 600px;
  border: 1px solid $grey-02;
  border-radius: 12px;
  padding: 24px;
}