@import '/src/styles/variables';

.wrapper {
  position: fixed;
  z-index: 999999;
}

.container {
  position: relative;
  padding: 16px;
  background-color: $color-primary-400;
  box-shadow: 14px 10px 50px 4px rgba(107, 107, 107, 0.25);
  border-radius: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.tip {
  padding: 8px;
  border-radius: 8px;
  background-color: $color-primary-200;
}

.cta {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
}

.buttonCta {
  height: unset;
  padding: 8px 16px;
  min-width: unset;
  border-radius: 12px;
}

.exit {
  position: absolute;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  color: $color-neutral-theme-300;

  &:hover {
    color: $color-neutral-theme-900;
  }
}

.exitBottom {
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
}

.exitTop {
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
}