@import '/src/styles/variables';

.customCheckboxWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.customCheckbox {
  display: none;
}

.checkboxMark {
  border: 2px solid $neutral-200;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customCheckbox:checked + .checkboxMark {
  background-color: $color-positive-500;
  border-color: $color-positive-500;
}

.customCheckboxWrapper:hover .checkboxMark {
  border-color: $color-positive-500;
}