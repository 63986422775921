@import '/src/styles/mixin';
@import '/src/styles/variables';

.navItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  border-radius: 24px;

  color: rgba(213, 250, 223, 0.6);

  &:hover {
    color: #F8FFEC;
  }

  &.active {
    color: #0C0C0C;
    background-color: #DCFF8F;
  }
}
