@import '/src/styles/variables';
@import '/src/styles/typography';

$tablet-width: 1024px;
$mobile-width: 700px;

.STContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  background-color: $color-neutral-theme-100;
  height: 100%;
}

.STCampaignPill {
  height: 32px;
  border: 1px solid $color-neutral-theme-800;
  border-radius: 25px;
  color: $color-neutral-theme-900;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  text-transform: unset;

  &_Selected {
    background-color: $color-neutral-theme-900;
  }

  &_Selected:hover {
    background-color: $color-neutral-theme-400;
  }

  &:hover {
    background-color: $color-neutral-theme-900;
  }
}

.STCampaignButton {
  height: 44px;
  border-radius: 12px;
  padding: 8px 16px;
  text-transform: unset;
}

.STCampaignButtonName {
  height: 44px;
  border-radius: 25px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  color: $color-neutral-theme-900;
  background-color: $color-neutral-theme-100;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &:hover,
  &:active {
    background-color: $color-neutral-theme-100 !important;
  }
}

.STHeaderLeft {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  position: relative;
}

.STCampaignArrowRight {
  position: absolute;
  right: 0;
  top: 50%;
  height: calc(100% + 4px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 44px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
  background: linear-gradient(270deg, #FFF 28.46%, rgba(255, 255, 255, 0.00) 100%);

  &:hover {
    svg {
      color: $color-neutral-theme-250;
    }
  }
}

.STCampaignArrowLeft {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 100%;
  cursor: pointer;

  &:hover {
    svg {
      color: $color-neutral-theme-250;
    }
  }

  z-index: 99;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.STHeaderRight {
  width: auto;
  flex: 0 0 auto;
}

.listCampaignContent {
  height: 100%;
  padding: 16px 24px 24px 24px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: 16px;
  background-color: $color-neutral-theme-100;

  @include onlyMobile() {
    padding: 12px !important;
  }
}

.STCampaignHeader {
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: $mobile-width) {
    flex-direction: column;
    margin-left: 0;
    gap: 8px;
  }

  @include onlyMobile() {
    flex-direction: column;
    margin-left: 0;
    gap: 8px;
  }
}

.STCampaignHeaderLeft,
.STCampaignHeaderRight {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;

  @media (max-width: $mobile-width) {
    gap: 8px;
    margin-right: 0;
  }

  @media (max-width: 340px) {
    gap: 2px;
  }
}

.STCampaignHeaderRight {
  @media (max-width: $mobile-width) {
    justify-content: space-between;
    margin: 0;
  }
}

.STCampaignHeaderTitle {
  gap: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: $color-neutral-theme-700;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: $mobile-width) {}

  &:hover {
    color: $color-neutral-theme-250;
  }
}

.STCampaignHeaderAction {
  color: $color-white !important;
  background-color: $color-neutral-theme-50 !important;
  border: 1px solid $color-neutral-theme-200;
  min-width: unset !important;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 12px;

  &:hover {
    background-color: $color-neutral-theme-50 !important;
    border: 1px solid $color-neutral-theme-700;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.STSubmissionContainer {
  position: relative;
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;

  grid-template-columns: repeat(2, 1fr);

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include mediaQueryMax($screenMedium) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax($screenSmall) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.STSubmissionItem {
  position: relative;
  gap: 16px;
  border-radius: 16px;
  background: $color-neutral-theme-50;
  background-blend-mode: screen, difference, normal;
}

.STSubmissionItemBottom {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px;
  background: $color-neutral-theme-50;
  border-radius: 0 0 12px 12px;
}

.STButtonIntro {
  border-radius: 12px;
  height: 40px;

  @include mobile() {
    width: 100%;
  }
}

.STVideo {
  position: relative;
  width: 100%;
  aspect-ratio: 343/601;
}

.STCampaignEmpty {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-neutral-theme-100;
}

// new
.campaignContainerWrapper {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &.showDetail {
    overflow: hidden;
    margin: 0 !important;

    @media screen and (max-width: 1275px) {
      overflow: auto;
    }

    @media screen and (max-height: 862px) {
      overflow: auto;
    }
  }
}

.campaignContainerData {
  position: relative;
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0px;

  @media only screen and (max-width: 1410px) {
    gap: 8px;
  }

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 900px) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @include onlyMobile() {
    margin: 0;
  }
}

.STLikesItem {
  z-index: 0;
  position: relative;
  border-radius: 16px;
  background: $color-neutral-theme-50;
  background-blend-mode: screen, difference, normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.STVideo {
  position: relative;
  width: 100%;
  @include aspect(343, 601);
  background-color: black;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.STVideoGuest {
  border-radius: 16px;
  overflow: hidden;

  &.Blur {
    filter: blur(4px);
  }
}

.STBottom {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid $color-neutral-theme-200;

  @media (max-width: 900px) {
    height: 56px;
  }
}

.STBottomText {
  font-family: 'Inter';
  font-style: normal;
}

.STBottomTitle {
  color: $color-neutral-theme-900;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.STBottomNote {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $color-neutral-theme-300;
}

.STButtonWrapper {
  display: flex;
  justify-content: space-between;
}

.STBottomWrapper {
  width: 100%;
}

.Icons {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: 42px;
  bottom: 0;

  @include onlyMobile() {
    flex-direction: column;
  }

  .Icon {
    border-radius: 100%;
    background-color: rgba(24, 25, 32, 0.7);
  }

  .IconLike {
    background-color: #4FDEE8
  }

  .Icon:nth-child(1) {
    position: absolute;
    bottom: 118px;
  }

  .Icon:nth-child(2) {
    position: absolute;
    bottom: 70px;
  }
}

.TraitBorder {
  border: 1px solid $color-neutral-theme-200;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding: 0 0 0 8px;

  p {
    font-weight: 500 !important;
  }

  width: 125px;
}

// .videoContainerPaddingBottom {
//   padding-bottom: $videoContainerPaddingBottom;

//   @media only screen and (max-width: 1380px) and (min-width: 900px) {
//     padding-bottom: 89px;
//   }
// }

.emptyVideos {
  padding: 0;
}

.FilterOption {
  width: 161px;

  @media (max-width: 840px) {
    width: 100%;
  }

  @media screen and (max-width: 905px) and (min-width: 840px) {
    width: 145px;
  }

  &_isShowSearchBar {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.ViewOption {
  width: 180px;

  @media (max-width: 840px) {
    width: 100%;
  }

  @media screen and (max-width: 905px) and (min-width: 840px) {
    width: 145px;
  }

  &_isShowSearchBar {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.ButtonSelected {
  color: white !important;
  background-color: $color-neutral-theme-900 !important;
  border: 1px solid transparent;

  &:hover,
  &:active {
    background-color: $color-neutral-theme-900 !important;
  }
}

.BtnCopy {
  background: $color-neutral-theme-50 !important;
  border-radius: 8px;
  padding: 8px;
  gap: 4px;
  width: 119px;
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  border: none;

  &:hover {
    background-color: $color-neutral-theme-50 !important;
  }
}

.ToastCopy {
  flex: 2;
  position: absolute;
  left: 103%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: $mobile-width) {
    left: calc(100% - 220px);
  }
}

.HeaderBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 25px;

  @include mobile() {
    justify-content: flex-start;
  }
}

.EmptyContainer {
  max-height: unset;
}

.EmptyWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile-width) {
    height: unset;
  }
}

.HeaderButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  @include mobile() {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}

.LeftWrapper {
  width: 117px;
  align-items: center;
  white-space: nowrap;

  @include tabletAndMobile() {
    width: auto;
  }

  @include onlyMobile() {
    order: 2;

    button {
      width: 40px;
      min-width: 40px;
      padding: 0;

      span {
        margin-left: 0;
        margin-right: 0;
      }

      .createJobText {
        display: none;
      }
    }
  }
}

.RightWrapper {
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  @include tabletAndMobile() {
    flex: 1;
    margin-right: 8px;
    margin-left: 8px;

    @include mediaQueryMax(600px) {
      margin-right: 0;
    }

    @include onlyMobile() {
      order: 1;
      margin-left: 0;
      margin-right: 8px;
    }

    svg {
      color: $color-neutral-theme-50;
      flex-shrink: 0;

      path {
        fill: currentColor;
      }
    }

    button {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: space-between;
      align-items: center;

      &:focus {
        background-color: $color-neutral-theme-900 !important;
      }
    }
  }
}

.HeaderBarWrapper::-webkit-scrollbar {
  display: none;
}

.STContainerGuess {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $color-neutral-theme-50;
  height: 100%;
}

.guessContainerWrapper {
  display: flex;
  flex-direction: row;
}

.STCampaignSampleWrapper {
  display: flex;
  flex-direction: row;
}

.STCampaignSample {
  width: 534px;
  height: 300px;
  background: rgba(0, 0, 0, 0.10);
}

.videoDisabled {
  pointer-events: none;
  opacity: 0.44;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-neutral-theme-200;
  z-index: 999999;
}

.pointerEventNone {
  pointer-events: none;
}

.disabledAction {
  position: relative;

  &::before {
    pointer-events: none;
    opacity: 0.44;
    background: $color-neutral-theme-200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }
}

.flipAnchor {
  position: absolute;
  right: 8px;
  bottom: 88px;
  width: 0;
  height: 0;
}

.viewAnchor {
  position: absolute;
  right: 8px;
  bottom: 28px;
  width: 0;
  height: 0;
}

.target {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 30%;
  left: 10%;
}