@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0 32px;
  flex-direction: column;
}

$width: 90%;
$max-width: 836px;

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $color-neutral-theme-900;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;

  margin: 0;
  width: $width;
  max-width: $max-width;
  padding-bottom: 24px;

  &_optional {
    color: $color-neutral-theme-250;
    margin-left: 8px;
  }
}

.content {
  margin: 0;
  width: $width;
  max-width: $max-width;

  display: flex;
  flex-direction: row;
  gap: 60px;

  @include onlyMobile() {
    flex-direction: column;
    gap: 32px;
  }
}

.subContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
}

.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: $color-neutral-theme-300;
  text-transform: capitalize;
}

.title_required {
  &::after {
    content: '*';
    color: $color-negative-500;
  }
}


.logo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    @media (max-width: 422px) {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }
  }

  &_upload {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $color-neutral-theme-300;
    }
  }

  &_button {
    padding: 8px 16px;
    width: 146px;
    height: 40px;
    border-radius: 12px;
  }
}

.companyName {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.companyTarget {
  position: absolute;
  left: 0px;
  top: 40px;
  width: 0;
  height: 0;
}

.companyWebsites {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.companyWebsites_buttonMore {
  width: 120px;
  height: 40px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.companyWebsites_buttonRemoveWebsite {
  margin-top: 11.5px !important;
  position: absolute !important;
  right: -34px;

  @include tabletAndMobile() {
    right: 0;
  }
}

.companyWebsites_inputWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  @include tabletAndMobile() {
    input {
      padding-right: 32px !important;
    }
  }

  svg {
    color: $color-neutral-theme-300;
  }
}

.introduceVibe {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_vibe {
    height: 369px;
    border: 1px dashed $color-neutral-theme-250;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &_videoWrapper {
    background-color: black;
    border-radius: 16px;
    overflow: hidden;
  }

  &_video {
    width: 100%;
    @include aspect(656, 369);
  }

  &_buttonCreate {
    width: 190px;
    height: 40px;
    border-radius: 12px;
  }

  &_buttonUpload {
    width: 190px;
    height: 40px;
    border-radius: 12px;
  }

  &_buttonSecondary {
    height: 40px;
    width: 190px;
    border-radius: 12px;
  }

  &_bottomButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 8px;
    }
  }
}