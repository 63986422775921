.loading {
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(36 37 45 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  // display: ${isOpen ? 'flex' : 'none'};
}