$screenXLarge: 1536px;
$screenLarge: 1200px;
$screenMedium: 900px;
$screenMediumSmall: 768px;
$screenSmall: 600px;
$screenExtraSmall: 375px;

$modalFullScreenBodyHeight: calc(100vh - 110px);
$modalFullScreenInnerBodyHeight: calc(100vh - 110px - 48px);

$videoContainerPaddingBottom: 85px;

$screenMobileL: 768px;
$screenTablet: 1024px;

$percent-values: (
  auto: auto,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  45: 45%,
  55: 55%,
  60: 60%,
  75: 75%,
  80: 80%,
  100: 100%
);
