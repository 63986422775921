@import '/src/styles/mixin';
@import '/src/styles/variables';

.screen {
  position: relative;
  margin: 0 auto;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;

  @include tablet() {
    flex-direction: column;
    align-items: center;
  }

  @include mobile() {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.recorderWrapper {
  display: flex;
  flex: 0 1 auto;
  align-items: center;

  position: relative;
  flex-direction: column;

  height: calc(var(--window-height, 100vh) - 68px); // header height
  max-width: 100vw;
  @include aspect(9, 16);

  padding: 16px 9px;

  @include mobile() {
    padding: 0;
    width: 100%;
  }
}

.recorderView {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  isolation: isolate;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;
  background-color: var(--color-neutral-500);

  border-radius: 16px;

  @include mobile() {
    border-radius: 0;
  }
}

.recorderMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  z-index: 99;
  left: 100%;
  // top: 50%;
  // @include transform(translate(24px, -50%));
  @include transform(translate(24px, 0));

  @include tablet() {
    left: unset;
    right: 40px;
  }

  @include mobile() {
    bottom: 32px;
  }
}

.recordingContainer {
  @include aspect(9, 16);
  height: calc(var(--window-height, 100vh) - 68px - 18px - 18px - 200px);
  top: 72px;
  position: relative;

  @include mediaQueryMax($screenLarge) {
    max-height: inherit;
    min-height: 480px;
    height: calc(var(--window-height, 100vh) - 76px);
    top: auto;
  }

  @include mediaQueryMax(500px + 1px) {
    @include aspect(9, 16);
    width: calc(100% - 24px);
  }
}

.previewBtns {
  @include box-shadow(0px 0px 30px 0px rgba(255, 255, 255, 0.5));
}