@import '/src/styles/variables';
@import '/src/styles/mixin';

.modalApplicantDetail {
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
}

.applicantWrapper {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 100px
}

.videoContainer {
  max-width: 403px;
  width: 100%;
}