@import '/src/styles/mixin';
@import '/src/styles/variables';

.questionInput > div {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}

.action {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $neutral-white;
  box-shadow: 0px 0px 20px 0px #00000014;
  padding: 20px 32px;
}
