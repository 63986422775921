@import '/src/styles/mixin';
@import '/src/styles/variables';

.STItem {
  flex: 1;
  display: flex;
  position: relative;
  gap: 11px;
  padding-bottom: 16px;

  &_notLastAndNotOnly {
    ::after {
      content: '';
      position: absolute;
      top: 20px;
      left: 135px;
      width: 1px;
      height: calc(100% - 16px);

      background: linear-gradient(
        to bottom,
        $color-neutral-theme-300,
        $color-neutral-theme-300 50%,
        $neutral-white 50%,
        $neutral-white
      );
      background-size: 100% 2px;
    }

    &_hideWorkDuration {
      left: 6px;
      ::after {
        content: '';
        position: absolute;
        top: 20px;
        left: 5px;
        width: 1px;
        height: calc(100% - 16px);

        background: linear-gradient(
          to bottom,
          #4FDEE8,
          #4FDEE8 50%,
          $neutral-white 50%,
          $neutral-white
        );
        background-size: 100% 2px;
      }
      &:last-child {
        ::after {
          display: none;
        }
      }
    }
  }
}

.STItemTime {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: $color-neutral-theme-900;
  width: 120px;
  flex-shrink: 0;
}

.STItemDescription {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
}

.STItemDescriptionLightmode {
  font-size: 12px !important;
  line-height: 20px !important;
}

.STItemDescriptionTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-900;
}

.STItemDescriptionDescription {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  color: $color-neutral-theme-900;
  text-align: justify;

  p {
    margin: 0;
  }
}

.STItemDescriptionCompanyAndLocation {
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-300;
}

.STNoDataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: $color-neutral-theme-900;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 16px;
}

.buttonUnsubscribe {
  height: 40px;
  width: 335px;

  @include tablet() {
    max-width: 335px;
    width: 100%;
  }
}