@import '/src/styles/mixin';
@import '/src/styles/variables';

.blackWallpaper {
  background: black !important;
}

.flip {
  video {
    transform: rotateY(180deg);
  }
}

.CCBtn {
  position: absolute;
  bottom: 18px;
  right: 8px;
  outline: none;
  border: 0;
  background-color: rgba(24, 25, 32, 0.7);
  border-radius: 8px;
  width: 35px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  z-index: 4;
}

.wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
}

.track {
  background-color: rgba(24, 25, 32, 0.7);
  border-radius: 4px;
  color: #fff;
  position: absolute;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 8px);
  text-align: center;
  padding: 0px 4px;

  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.videoProcessingContainer {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0px;
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
  z-index: 9999999;
}

