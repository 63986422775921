@import '/src/styles/mixin';
@import '/src/styles/variables';

.STContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 16px;
  background-color: var(--color-neutral-theme-100);

  @media screen and (max-width: 1275px) {
    min-width: 1000px;
  }

  @media screen and (max-height: 862px) {
    min-height: 720px
  }

  @include tabletAndMobile() {
    min-width: unset;
    min-height: unset;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    border-top: 1px solid rgb(218, 219, 231);
  }
}

.STContainerGuest {
  padding: 0;
}

.submissionDetailContent {
  display: flex;

  overflow: hidden;
  aspect-ratio: 1076 / 826;
  border-radius: 16px;
  background-color: var(--color-white);

  min-width: 1000px;

  &_Guest {
    padding: 0 16px 16px 16px;
  }

  @media screen and (max-width: 1360px) {
    min-width: 900px
  }

  .tabSpacing {
    padding-top: 12px;
    padding-bottom: 12px;

    @media screen and (max-width: 1360px) {
      gap: 8px;
    }
  }

  @include tabletAndMobile() {
    min-width: unset;
    min-height: unset;
    aspect-ratio: unset;
    height: 100%;
    width: 100%;
  }

  @include onlyMobile() {
    border-radius: 0;
    height: unset;
    padding-bottom: 16px;

    flex-direction: column;
    overflow: auto;
  }

  .infoContainer {
    flex: 1 1;

    @include tablet() {
      width: 50%;
      overflow: hidden;
      flex: unset;
    }

    @include onlyMobile() {
      width: unset;
    }

    &_showingComment {
      @include tablet() {
        width: 50%;
      }

      @include onlyMobile() {
        width: 100%;
      }
    }
  }

  .mobileDivider {
    height: 8px;
    width: 100%;
    content: '';
    display: inline-block;
    padding-top: 10px;
    background-color: $color-neutral-theme-100;
  }

  .videoContainer {
    border-right: 1px solid rgb(218, 219, 231);
    aspect-ratio: 432 / 826;
    height: 100%;
    width: auto;

    @include tabletAndMobile() {
      aspect-ratio: unset;
      flex: 1 1;
    }

    @include onlyMobile() {
      padding: 0 0 16px 0 !important;
    }
  }

  .videoPlayer {
    width: 100%;
    height: 100%;

    background-color: black;

    @include tabletAndMobile() {
      @include aspect(322, 570);
      min-width: 200px;
      width: 100%;
      height: auto;
      border-right: none;
    }

    @include onlyMobile() {
      border-radius: 0 !important;
    }
  }
}

.STNotes {
  position: relative;
  div {
    margin-top: 4px;
  }
}

.STSubmissionInfoLabel {
  font-size: 14px;
  font-weight: 700;
  color: $color-neutral-theme-300;
}

.STSubmissionInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
}

.STSubmissionInfoSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.STResumeFile {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 245px;
  border-radius: 8px;
  background-color: $color-neutral-theme-100;
  padding: 10px 16px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $color-neutral-theme-200;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $color-neutral-theme-700;
  }
}

.STPorfolioLink {
  font-size: 14px;
  font-weight: 400;
  color: $color-cyan-600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $color-cyan-700;
  }
}

.STNoPorfolioLink {
  font-size: 14px;
  font-weight: 400;
  color: $color-cyan-600;
  text-decoration: none;

  &:hover {
    color: $color-cyan-700;
  }
}


.STLike {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-cyan-100;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: block;
    color: $color-cyan-400;
  }
}

.STUnLike {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background: $color-neutral-theme-100;
}

.STUnLikeIcon {
  path {
    fill: $color-neutral-theme-900;
  }
}

.STButtonShare {
  height: 40px;
  background: $color-neutral-theme-100;
  border-radius: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  color: $color-neutral-theme-900;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color-neutral-theme-200;
  }
}


.FullNameTitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  font-family: 'Inter';
  color: $color-neutral-theme-700;
  margin: 0;
  margin-bottom: 12px;
}

.LabelMethod {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Inter';
  color: $color-neutral-theme-300;
  margin: 0;
  margin-bottom: 8px;
}

.STPersonal {
  word-break: break-word;

  display: flex;
  flex-direction: column;
  gap: 8px;

  div {
    color: $color-neutral-theme-700;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.STPrivateNotes {
  font-size: 14px;
}

.reactionAndShare {
  display: flex;
  gap: 8px;

  @include tabletAndMobile() {
    justify-content: center;
  }
}

.targetVideo {
  position: absolute;
  top: 200px;
  left: 80px;
  width: 0;
  height: 0;
}

.STSubmissionInfoUL {
  margin: 0;
  padding: 0 0 0 24px;
  display: flex;
  flex-direction: column;

  span {
    display: list-item;
  }
}