.STContainer {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 16px;
  align-items: center;
}

.STLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}