@import '/src/styles/mixin';
@import '/src/styles/variables';

$date_picker_bg_color: #FFFFFF;
$date_picker_color: $black-01;
$date_picker_disabled_color: $neutral-40;
$date_picker_hover_color: $green-75;
$date_picker_selected_color: $green-300;
$date_picker_select_bg_color: transparent;

.datePickerPopper {
  padding: 0 !important;
  line-height: 0;
  z-index: 9999;
}

.datepicker {
  :global {
    .react-datepicker {
      background-color: $date_picker_bg_color;
      border: 1px solid $neutral-N500;
      font-size: 1.2rem;
      padding: 16px;
      border-radius: 12px;
      border: 1px solid $neutral-N500;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      padding: 0;
      border-bottom: none;
      background-color: $date_picker_bg_color;
    }

    .react-datepicker__month {
      margin: 0;
      background-color: $date_picker_bg_color;
    }

    .react-datepicker__day-names {
      padding-top: 8px;
      margin-top: 8px;
      font-size: 1.2rem;
      font-weight: $regularWeight;
      color: #7C7C7C;
      border-top: 1px solid #E2E2E2;
    }

    .react-datepicker__day-name {
      color: #7C7C7C;
      width: 3rem;
      line-height: 3rem;
    }

    .react-datepicker__day {
      border-radius: 999rem;
      width: 3rem;
      line-height: 3rem;
      color: $date_picker_color;

      &:hover, &--keyboard-selected {
        background-color: $date_picker_hover_color;
      }

      &--selected {
        background-color: $date_picker_selected_color !important;
      }

      &--disabled {
        color: $date_picker_disabled_color;
        background-color: transparent !important;
      }
    }

    .react-datepicker__day--outside-month {
      color: transparent !important;
      pointer-events: none;
      background-color: transparent !important;
    }

    .react-datepicker-popper[data-placement^="top"] {
      margin-bottom: 8px;
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      margin-top: 8px;
    }
  }
}

.yearSelect,
.monthSelect {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: $semiBoldWeight;
  border-radius: 4px;
  background-color: $date_picker_select_bg_color;
  color: $date_picker_color;
  appearance: none;
  -webkit-appearance: none;
}

.buttonPrevious,
.buttonNext {
  width: 32px;
  height: 32px;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $date_picker_hover_color;
  }
}
