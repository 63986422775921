@import '/src/styles/variables';

.container {
  background-color: $color-neutral-theme-50;
  border-radius: 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 464px;
}

.checkBoxRemember {
  color: $neutral-300 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
