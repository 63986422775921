@import '/src/styles/mixin';
@import '/src/styles/variables';

.Comment {
  .CommentForm {
    flex: 1;
    background-color: $color-neutral-theme-700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    margin: auto;

    @include mediaQueryMax($screenMediumSmall) {
      padding: 24px;
      border-radius: 16px;
      flex-wrap: wrap;
      gap: 24px;
    }

    .CommentedLabel {
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      margin: 0;
      color: $color-white;

      @include mediaQueryMax($screenMediumSmall) {
        text-align: center;
        font-weight: 700;
      }
    }

    .LogInBtn {
      width: 120px;
      font-size: 16px;

      @include mediaQueryMax($screenMediumSmall) {
        width: 100%;
      }
    }

    .Input {
      flex: 1;
      display: flex;
      align-items: flex-start;
      background-color: $color-neutral-theme-500;
      border-radius: 16px;
      padding: 10px 14px 10px;
      gap: 14px;

      @include mediaQueryMax($screenMediumSmall) {
        padding: 14px;
      }

      .Textarea {
        flex: 1;
        min-height: 20px;
        font-size: 16px;
        padding: 0;
        background-color: transparent;
        color: $color-white;
        margin-top: 8px;
        border-radius: 0;
        box-shadow: none !important;

        @include mediaQueryMax($screenMediumSmall) {
          margin-top: 4px;
        }
      }

      .TextareaWrapper {
        flex: 1;
        min-height: 20px;
      }

      .EmojiBtn {
        padding: 0;
        margin-top: 8px;
      }
    }


    .SubmitBtn {
      width: 68px;
      color: $color-white !important;
      border: 1px solid $color-neutral-theme-300;

      &:hover {
        background-color: $color-neutral-theme-300 !important;
      }

      &:disabled {
        opacity: 0.5;
      }

      @include mediaQueryMax($screenMediumSmall) {
        width: 100%;
      }
    }
  }
}