@import '/src/styles/mixin';
@import '/src/styles/variables';

.SectionSendMail {
  width: 560px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  gap: 32px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }
}

.mainText {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.64px;
  text-transform: capitalize;
}

.subText {
  @include onlyMobile() {
    padding: 0 8px;
  }
}

.email {
  color: $color-neutral-theme-900;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
