.container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 34px;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    h5,
    span {
      max-width: 437px;
      text-align: center;
    }

    .vibeBtn {
      margin-top: 12px;
      padding: 16px 32px;
    }
  }
}