@import '/src/styles/mixin';
@import '/src/styles/variables';

.label {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: $neutral-500;
}

.textareaContainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 10px;
  width: 100%;

  textarea {
    background: transparent;
  }

  @include transition(box-shadow $transition-duration ease-in-out);
  @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  &:hover {
    @include box-shadow(inset 0px 0px 0px 1px $neutral-50);
  }
  &.focused {
    @include box-shadow(inset 0px 0px 0px 2px $green-75);
  }
  &.error {
    @include box-shadow(inset 0px 0px 0px 1px $negative-500);
  }
  &.disabled {
    cursor: not-allowed;
    color: $neutral-40;
    background-color: $neutral-10;
    @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  }
}

.textarea {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $neutral-900;
  min-height: 100px;
  white-space: pre-wrap;
  word-break: break-word;

  [contenteditable="false"] {
    user-select: none;
    cursor: default;
    display: inline-block;
  }

  .contentEditable {
    min-width: 40px;
    outline: none;
  }
}

.innerError {
  position: absolute;
  bottom: 0;
  right: 4px;
  font-size: 1.1rem;
  line-height: 1.6rem;
}