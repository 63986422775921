@import '/src/styles/mixin';
@import '/src/styles/variables';

.btn {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  padding: 8px 20px;
  border: 0;
  border-radius: 99px;
  // @include border(rgba(#000000, .2), 2px);
  @include transition(all 0.3s linear);

  &:disabled {
    cursor: auto;
  }
  // &:not(:disabled) {
  //   // revert svg color
  //   &:hover, &:active {
  //     svg {
  //       -webkit-filter: invert(1) hue-rotate(180deg);
  //       filter: invert(1) hue-rotate(180deg);
  //     }
  //   }
  // }

  &.label {
    color: $neutral-800;
    background: $green-200;
    &:hover {
      background: $green-300;
    }
    &.gradient {
      color: $neutral-white;
      background: $neutral-800;
      &:hover, &:active {
        color: $neutral-800;
      }
      &:hover {
        background: linear-gradient(90deg, #D4D94A 0%, #CCF241 25%, #C2F213 41%, #9EF291 75.5%, #7EF2A7 100%);
      }
    }

    &:disabled, &.gradient:disabled {
      color: $neutral-800;
      background: $neutral-40;
    }
  }

  &.primary {
    color: $neutral-white;
    background: $neutral-800;
    &.green {
      background: $green-100;
    }
    &.green, &:hover, &:active {
      color: $neutral-800;
    }

    &:hover {
      background: $green-300;
    }

    &:active {
      background: $green-400;
    }

    &:disabled, &.green:disabled {
      color: $neutral-white;
      background: $neutral-40;
    }
  }

  &.positive {
    border-radius: 8px;
    border-top-right-radius: 0%;
    border-bottom-left-radius: 0%;
    background-color: #78E59E;
    color: black;

    &:hover {
      background-color: darken(#78E59E, 10%);
    }

    &:active {
      background-color: darken(#78E59E, 15%);
    }

    &:disabled {
      background-color: lighten(#78E59E, 20%);
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.secondary {
    color: $neutral-800;
    background: $neutral-10;
    &:hover {
      background: $neutral-30;
    }
    &:active {
      background: $neutral-40;
    }

    &.emphasis {
      color: $neutral-white;
      background: $neutral-800;
      &:hover, &:active {
        color: $neutral-800;
      }
      &:hover {
        background: $green-300;
      }
      &:active {
        background: $green-400;
      }
    }

    &:disabled, &.emphasis:disabled {
      color: $neutral-white;
      background: $neutral-40;
    }
  }

  &.icon {
    padding: 4px;

    color: $neutral-800;
    background: $neutral-10;
    &:hover {
      background: $neutral-30;
    }
    &:active {
      background: $neutral-40;
    }

    &.active {
      color: $neutral-white;
      background: $neutral-600;
      &:hover, &:active {
        background: $neutral-500;
      }
    }

    &.green {
      background: $green-100;
      &:hover, &:active {
        background: $green-300;
      }
    }

    &.red {
      color: $neutral-white;
      background: $red-02;
      &:hover, &:active {
        background: $red-02;
      }
    }

    &.black {
      color: $neutral-white;
      background: $black-02;
      &:hover, &:active {
        background: $black-02;
      }
    }

    &.emphasis {
      background: $turquoise-300;
      &:hover, &:active {
        background: $turquoise-400;
      }
    }
  }
}
