@import '/src/styles/variables';

.STResumeFile {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  border-radius: 12px;
  background-color: $color-neutral-theme-50;
  box-shadow: 0 0 0 1px $color-neutral-theme-200;
  padding: 8px 16px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;

  &:hover {
    background-color: $color-neutral-theme-200;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $color-neutral-theme-700;
    flex: 1;
  }

  .iconFile {
    flex-shrink: 0;
  }

  .close {
    flex-shrink: 0;
    width: 13.3px;
    height: 13.3px;
    color: $color-neutral-theme-250;
    &:hover {
      color: $color-neutral-theme-700;
    }
  }
}
