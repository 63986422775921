#root {
    width: 100vw;
    height: 100vh;
    height: var(--window-height);
    display: flex;
    flex-direction: column;
    overflow: auto;
}

/* dropdown MuiAutocomplete */
.MuiAutocomplete-popper {}

.MuiAutocomplete-paper {
    box-shadow: 0px 24px 56px -12px rgb(37 38 46 / 10%) !important;
    margin-top: 16px;
    border-radius: 16px !important;
}

.MuiAutocomplete-noOptions {
    color: var(--color-neutral-theme-300) !important;
    display: flex;
    justify-content: center;
    align-items: center
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.react-card-front, .react-card-back {
    transform-style: initial !important
}