@import '/src/styles/mixin';
@import '/src/styles/variables';

.signInForm {
  width: 464px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  button[type="submit"]{
    width: 169px;
    align-self: flex-end;
  }
}

.LoginHeader {
  text-align: center;
  margin-bottom: 28px;
  display: flex;
  gap: 18px;

  .Title {
    position: relative;
    color: $neutral-700;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.64px;
    text-transform: capitalize;

    span {
      position: absolute;
      font-size: 12px;
      top: 4.5px;
      right: -19px;
    }
  }

  .Description {
    display: flex;
    justify-content: center;
    color: $neutral-400;
    font-size: 24px;
    font-weight: 600;

    &:before {
      content:"";
      display: inline-block;
      margin: 3px 12px 0 12px;
      width: 1px;
      height: 30px;
      background-color: $neutral-400;
    }
  }
}

.Box {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;

  @include onlyMobile() {
    flex-direction: column;
  }
}

.FormInput {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 28px;
  margin-bottom: 32px;
}
