.pwdCheckList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4px;
  margin-bottom: 0;
  padding: 0;
  margin-top: 8px;
}

.pwdCheckItem {
  list-style-type: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition: all .3s ease-in-out;

  &:before {
    transition: all .3s ease-in-out;
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background: var(--color-neutral-theme-200);
  }
}

.passed {
  color: var(--color-neutral-theme-700);
  &:before {
    background: var(--color-positive-600);
  }
}

.failed {
  color: var(--color-negative-500);
  &:before {
    background: var(--color-negative-500);
  }
}