@import '/src/styles/mixin';
@import '/src/styles/variables';

.companyInfo {
  z-index: 1;
  background-color: $color-white;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  gap: 24px;

  border: 1px solid $color-neutral-theme-200;

  @include tabletAndMobile() {
    flex-direction: column;
  }

  .Avatar {
    border-radius: 12px;
    background-color: $color-neutral-theme-100;
    @include avatar(191px, 191px);
  }

  .companyName {
    margin: 0 0 12px 0;
    color: $color-neutral-theme-900;
    margin-top: 24px;
    margin-bottom: 0;
  }
}
