@import '/src/styles/mixin';
@import '/src/styles/variables';

.STContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  background: $color-neutral-theme-100;

  .Logo {
    margin-bottom: 48px;
  }
}

.STWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-neutral-theme-50;
  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.1);
  border-radius: 40px;
  max-width: 536px;
  padding: 48px;

  .Title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.8px;
    text-transform: capitalize;
    padding-bottom: 24px;
  }

  .SubText {
    color: $color-neutral-theme-300;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  .BtnSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
  }

  .VerifyButton {
    border-style: none;
  }
}

.iconVerifiedEmail {
  width: 203px;
  height: 140px;

  @include onlyMobile() {
    width: 203px;
    height: 140px;
  }
}