@import '/src/styles/mixin';
@import '/src/styles/variables';

.reactVideoPlayer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 16px;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all $transition-duration ease-in-out;
  }
  &:hover {
    .overlay {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .uploadProgressing {
      transform: translateY(-60px);
    }
  }

  .speaker {
    z-index: 3;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all $transition-duration ease-in-out;
    z-index: 3;

    &.pausing {
      opacity: 0;
    }
  }

  &:hover {
    .play {
      opacity: 1;
    }
  }

  video::-webkit-media-text-track-display {
    height: 0 !important;
    font-size: 0 !important;
  }

  .track {
    position: absolute;
    bottom: max(30px, 10%);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 16px);
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.8rem;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  }

  &.fullScreen {
    .track {
      width: calc(100% - 48px);
    }
  }

  &.flip {
    video {
      transform: rotateY(180deg);
    }
  }
}

.uploadProgressing {
  z-index: 2; // lower than the speaker icon
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: all $transition-duration ease-in-out;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: rgba(24, 25, 32, 0.7);
}

.progressBar {
  width: 100%;
  height: 2px;
}

.activeProgressBar {
  transition: all $transition-duration ease-in-out;
  height: 2px;
  top: 0;
  left: 0;
  position: absolute;
}

.loadingContainer {
  height: 40px;

  .loadingIcon {
    animation: var(--spin-animation, spin) 1s linear infinite;
    transform: scale(0.7);
  }
}

.logoLoadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}