@import "/src/styles/variables";
@import "/src/styles/mixin";

.selectWrapper {
  .menuContainer {
    width: 100%;
    background: $neutral-white;
    margin: 8px 0 0 !important;
    border-radius: 8px;
    border: 1px solid $neutral-N500;
    box-shadow: none;
    overflow: hidden;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .selectTypo {
    @include typo(1.4rem, 400, 1.8rem);
    color: $neutral-black;
    margin: 0;
    padding: 0;
  }

  .selectControl {
    display: flex;
    color: $neutral-black;
    background-color: $neutral-white;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid $neutral-N500;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    pointer-events: initial;
    cursor: pointer;

    &.noBg {
      background: transparent;
      border-color: transparent;
    }

    &.disabled {
      background: $neutral-10 !important;
      // color: $neutral-40;
      border: 1px solid $neutral-N500;
      cursor: not-allowed;

      // .selectTypo {
      //   color: $neutral-40;
      // }

      // .placeholder {
      //   color: $neutral-40 !important;
      // }
    }

    &.error {
      border: 1px solid $color-negative-500;
    }

    &:not(.disabled):not(.error):hover,
    &:not(.disabled):not(.error).focused {
      border: 1px solid $green-300;
      box-shadow: 0px 0px 0px 1px $green-500;
    }
  }

  .selectValueContainer {
    padding: 0;
    padding-left: 12px;
    gap: 4px;

    &.selectMultiValueContainer {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .selectIndicatorSeparator {
    display: none;
  }

  .clearIndicator {
    display: none;
  }

  .dropdownIndicator {
    color: inherit !important;
    padding: 0 12px;
  }

  .input > input {
    opacity: 1 !important;
  }

  .input,
  .singleValue {
    @extend .selectTypo;
  }

  .placeholder {
    @extend .selectTypo;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $neutral-50 !important;
  }

  .option {
    display: flex;
    align-items: center;
    color: $neutral-black;
    background: $neutral-white;
    padding: 12px;
    cursor: pointer;
    @extend .selectTypo;

    &.disabled {
      background: rgba(0, 0, 0, 0.04) !important;
      cursor: not-allowed;
    }

    &.focused {
      background: $green-500;
    }

    &.Selected {
      background: $green-500;
    }
  }

  .menuList {
    padding: 0;
    scroll-behavior: initial;
    max-height: 200px;
  }

  .multiValue {
    color: $neutral-black;
    margin: 0;
    padding: 4px 8px;
    border-radius: 999rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .multiValueRemove {
    cursor: pointer;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: $color-negative-500;
    }
  }

  .multiValueLabel {
    padding: 0;
  }

  .noOptionsMessage {
    @extend .selectTypo;
    padding: 12px;
  }

  .helperText {
    margin-top: 8px;
    color: $neutral-50;
    @include typo(1.2rem, 500, 1.6rem);

    &.helperTextError {
      color: $color-negative-500;
    }
  }

  /** Size */

  &.large {
    .selectValueContainer {
      padding-left: 12px;
    }

    .dropdownIndicator {
      padding: 0 12px;
    }

    .option {
      padding: 12px;
    }

    .selectTypo {
      @include typo(1.4rem, 400, 1.8rem);
    }
  }

  &.medium {
    .selectValueContainer {
      padding-left: 12px;
    }

    .dropdownIndicator {
      padding: 0 12px;
    }

    .option {
      padding: 12px;
    }

    .selectTypo {
      @include typo(1.4rem, 400, 1.8rem);
    }
  }

  &.xMedium {
    .selectValueContainer {
      padding-left: 12px;
    }

    .dropdownIndicator {
      padding: 0 12px;
    }

    .option {
      padding: 12px;
    }

    .selectTypo {
      @include typo(1.2rem, 400, 1.6rem);
    }
  }

  &.small {
    .selectValueContainer {
      padding-left: 8px;
    }

    .dropdownIndicator {
      padding: 0 8px;
    }

    .option {
      padding: 8px;
    }

    .selectTypo {
      @include typo(1.2rem, 400, 1.6rem);
    }
  }

  &.xSmall {
    .selectValueContainer {
      padding-left: 8px;
    }

    .dropdownIndicator {
      padding: 0 8px;
    }

    .option {
      padding: 8px;
    }

    .selectTypo {
      @include typo(1.2rem, 400, 1.6rem);
    }
  }
}
