@import '/src/styles/mixin';
@import '/src/styles/variables';

.inputContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 10px;
  width: 100%;
  position: relative;

  input {
    background: transparent;
  }

  @include transition(box-shadow $transition-duration ease-in-out);
  @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  &:hover {
    @include box-shadow(inset 0px 0px 0px 1px $neutral-50);
  }
  &.focused, &:focus {
    @include box-shadow(inset 0px 0px 0px 2px $green-75);
  }
  &.error {
    @include box-shadow(inset 0px 0px 0px 1px $negative-500);
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $neutral-40;
    background-color: $neutral-10;
    @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  }
}

.input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $neutral-900;
}

.iconSearch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
