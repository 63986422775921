@import '/src/styles/mixin';
@import '/src/styles/variables';

.STForm {
  width: 560px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;

  .Logo{
    margin-bottom: 16px;
  }

  .LoginHeader {
    text-align: center;
    margin-bottom: 32px;
    display: flex;
    gap: 18px;

    .Title {
      position: relative;
      color: $neutral-700;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.64px;
      text-transform: capitalize;
      span {
        position: absolute;
        font-size: 12px;
        top: 4.5px;
        right: -19px;
      }
    }

    .Description {
      display: flex;
      justify-content: center;
      color: $neutral-400;
      font-size: 24px;
      font-weight: 600;
      &:before {
        content:"";
        display: inline-block;
        margin: 3px 12px 0 12px;
        width: 1px;
        height: 30px;
        background-color: $neutral-400;
      }
    }
  }

  .Box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    gap: 28px;

    button[type="submit"]{
      width: 160px;
      align-self: flex-end;
      text-transform: capitalize;
    }
  }

  .ErrorMsg {
    padding: 8px 12px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    gap: 4px;

    svg {
      fill: $color-negative-500;
    }
  }

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }
}
