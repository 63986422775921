@import '/src/styles/mixin';
@import '/src/styles/variables';

.sidebar {
  @include box-shadow(0px 16px 44px 0px rgba(0, 0, 0, 0.07));
  color: rgba(249, 254, 231, 0.6);
  background-color: #0C0C0C;
  position: relative;
  z-index: 2;

  @include transition(width 0.3s linear, min-width 0.3s linear);
  width: 93px;
  min-width: 93px;
  &.expanded {
    width: 280px;
    min-width: 280px;
  }
}

.toggleIcon {
  position: absolute;
  top: 22px;
  right: 0;
  transform: translateX(50%);
  width: 24px;
  height: 24px;
  border-radius: 99px;
  border: 1px solid #595959;
  background-color: #fff;
  z-index: 1000;
}
