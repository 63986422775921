.layoutContainer {
  flex: 1 1 auto;
  display: flex;
  width: 100vw;
  overflow: hidden;
}

.layoutContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
}

.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #F7F7F4;
}
