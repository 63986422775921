@import '/src/styles/mixin';
@import '/src/styles/variables';

.dialogVerifyProfile {
  width: 415px;
  border-radius: 16px;
  padding: 40px;
  background-color: $color-neutral-theme-50 !important;
  box-shadow: 0px 40px 80px -12px rgb(37 38 46 / 10%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 18px;
    color: $color-neutral-theme-900;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
  }

  @include mobile() {
    width: 100%;
    padding: 24px;
  }
}
