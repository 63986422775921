@import '/src/styles/variables';

.STIcons {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: calc(100% - 84px);
  gap: 12px;
  z-index: 3;
  position: absolute;

  bottom: 70px;
  right: 8px;
}

.STPopper {
  position: absolute;
  right: -18px;
  top: -58px;
  box-shadow: 0px 8px 16px rgb(37 38 46 / 20%);
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.60);
  padding: 8px;

  display: flex;
  align-items: center;
  gap: 8px;
}

.Icon {
  border-radius: 100% !important;
  background-color: rgba(24, 25, 32, 0.7) !important;
}