@import '/src/styles/variables';

.StopBtn {
  outline: none;
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 2px;
  position: relative;

  .StopBtn {
    background-color: rgba(255, 255, 255, 0.4);
    width: 82px;
    height: 82px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .StopBtnBackground {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: $color-negative-500;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    padding-bottom: 4px;
    font-size: 14px;
    color: $color-white;
  }
}