@import '/src/styles/mixin';
@import '/src/styles/variables';

.MoreInfoBtn {
  height: 40px!important;
  border-radius: 12px!important;
}

.STContainerWrapper {
  position: relative;
  flex: 1;
  margin: 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile() {
    margin: 24px;
  }

  @include onlyMobile() {
    margin: 12px;
  }

  &.showDetail {
    overflow: hidden;
    margin: 0 !important;

    @media screen and (max-width: 1275px) {
      overflow: auto;
    }

    @media screen and (max-height: 862px) {
      overflow: auto;
    }
  }
}

.STFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;
}

.STFilterLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-300;
}

.STVideoWrapper {
  position: relative;
}

.videoContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 343/601;
  background-color: black;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.STNewButton {
  position: absolute;
  color: $color-white !important;
  background-color: $color-negative-400!important;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 100;
  font-size: 14px;
  font-weight: 700;
  padding: 4px;
}