@import '/src/styles/mixin';
@import '/src/styles/variables';

.action {
  position: sticky;
  bottom: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $neutral-white;
  box-shadow: 16px 0px 44px 0px #00000012;
  padding: 16px 24px;
}
