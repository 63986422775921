@import 'variables';
@import 'mixin';

.svg-current-color {
  path {
    fill: currentColor;
  }
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/**
 * @position helpers
 */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

/**
 * @size helpers
 */

.w-50-p {
  width: 50% !important;
}

.w-100-p {
  width: 100% !important;
}

.w-fit-content {
  width: fit-content;
}

.w-100{
  width: 100%;
}

.h-100{
  height: 100%;
}

/**
 * @fonts helpers
 */
.fw-hero {
  font-weight: 900;
}
.fw-bold {
  font-weight: 700;
}
.fw-semi-bold {
  font-weight: 600;
}

.text-tiny {
  font-size: 16px;
}
.text-small {
  font-size: 16px;
}
.text-medium {
  font-size: 14px;
}
.text-large {
  font-size: 12px;
}

.txt-center,
.text-center {
  text-align: center;
}

.video-txt-shadow {
  text-shadow: 0px 1px 2px var(--color-neutral-900);
}

.d-none, .hidden {
  display: none !important;
}

/** overflow helpers */
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.oneline-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.twoline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.threeline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-decorator-none {
  text-decoration: none;
}

.text-decorator-strike {
  text-decoration: line-through;
}

.text-decorator-underline {
  text-decoration: underline;
}

.leading-27px {
  line-height: 27px;
}

.capitalize {
  text-transform: capitalize;
}

.shadow-none {
  box-shadow: none !important;
}

.max-w-unset {
  max-width: unset !important;
}

.pointer-event-none {
  pointer-events: none;
}

.pointer-event-all {
  pointer-events: all;
}

.rotate-180 {
  transform: rotate(180deg);
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.transform-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.px-3px {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.rounded, .round-full {
  border-radius: 999rem !important;
}

.border-none {
  border: none;
}

.outline-none {
  outline: none;
}

.transition-none {
  transition: none !important;
}
