@import '/src/styles/variables';

.ccContainer {
  background-color: $neutral-white;
}

.borderTop {
  border-top: 1px solid #DADBE7;
}

.ccHeader {
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
}

.ccTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.ccToggleIcon {
  transition: all 0.2s ease-in-out;
  height: 20px;
}