@import '/src/styles/mixin';
@import '/src/styles/variables';

@include keyframes(scaleOpen) {
  0% {
    opacity: 0;
  }
  100% {}
}

@include keyframes(scaleClose) {
  0% {}
  99% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: var(--window-height, 100vh);
  background-color: rgba(0, 0, 0, 0.5);
  &.open {
    @include animation(scaleOpen 0.2s ease-in-out forwards);
  }
  &.closed {
    @include animation(scaleClose 0.2s ease-in-out forwards);
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-8px, 8px);
}