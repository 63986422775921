@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 32px;
  flex-direction: column;
  width: 100%;
  max-width: 946px;
  margin: 0 auto;
}

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $color-neutral-theme-900;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  position: relative;

  margin: 0;
  padding-bottom: 24px;

  &_optional {
    color: $color-neutral-theme-250;
  }

  &_icon {
    margin-left: 20px;
    transition: all 0.3s ease-in-out;

    &_rotate {
      transform: rotate(180deg);
    }
  }
}


.content {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.campaignWrapper {
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;

  @include mediaQueryMax(745px) {
    flex-direction: column;
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $black_01;
}

.title_required {
  &::after {
    content: '*';
    color: $color-negative-500;
  }
}

.title_bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: $color-neutral-theme-900;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-300;
}

.campaignName {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
}

.campaignTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.salary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.location {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.question {
  border: 1px solid $color-neutral-theme-200;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  &_header {
    display: flex;
    flex-direction: column;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.bordering {
  border: 1px solid $color-neutral-theme-200;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.questionInput > div {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}

.requirementsContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.jd {
  border: 1px solid $color-neutral-theme-200;
  border-radius: 16px;
  overflow: hidden;
}

.jd_attach_button {
  color: $color-cyan-500 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding: 0;
  height: auto;
  border-radius: 8px;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.charCount {
  position: absolute;
  right: 16px;
  bottom: 18px;
}

.jobDetailTarget {
  position: absolute;
  left: 0;
  top: 52px;
  width: 10px;
  height: 10px;
}

.jobDescriptionTarget {
  position: absolute;
  left: 0;
  top: 78px;
  width: 10px;
  height: 10px;
}

.questionTarget {
  position: absolute;
  left: 0;
  top: 90px;
  width: 10px;
  height: 10px;
}


.iconInfo {
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    path {
      fill: $color-neutral-theme-900;
    }
  }
}

.popper {
  z-index: 99999 !important;
}

.tooltip {
  background-color: $color-neutral-theme-700 !important;
  padding: 8px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.tooltipArrow {
  &::before {
    background-color: $color-neutral-theme-700 !important;
  }
}

.jdEditor {
  [class*='ql-container'] {
    margin-top: 42px;
  }

  @include mediaQueryMax(350px) {
    [class*='ql-container'] {
      margin-top: 52px;
    }
  }
}

.jdEditorWithAttachment {
  [class*='ql-container'] {
    margin-top: 54px;
  }

  @include mediaQueryMax(350px) {
    [class*='ql-container'] {
      margin-top: 64px;
    }
  }
}

.jdAttachmentWrapper {
  position: absolute;
  top: 120px;
  left: 15px;

  @include mediaQueryMax(350px) {
    top: 136px;
  }
}

.jdWithAttachment {
  top: 120px;

  @include mediaQueryMax(350px) {
    top: 130px;
  }
}