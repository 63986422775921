@import '/src/styles/mixin';
@import '/src/styles/variables';

.icon {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  @include transition(all $transition-duration linear);

  &[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 100%;
    min-width: 96px;
    width: fit-content;
    word-wrap: break-word;
    border-radius: 4px;
    display: flex;
    padding: 8px;
    @include box-shadow(0px 5px 20px rgba(0, 0, 0, 0.25));
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
  }

  &[data-tooltip]:hover::after {
    opacity: 1;
    visibility: visible;
  }

  .tooltipTop {
    bottom: 100%;
  }

  .tooltipBottom {
    top: 100%;
  }

  .tooltipRight {
    left: 100%;
  }

  .tooltipLeft {
    right: 100%;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  &.bg {
    background: var(--color-neutral-100);
  }

  &.disabled {
    cursor: not-allowed;
  }
}
