* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  font-size: 10px;
  line-height: 1.4em;
  @each $color, $value in $colors {
    --#{$color}: #{$value};
    --color-#{$color}: #{$value};
  }
}

body {
  overflow: hidden;
  font-size: 1.4rem;
  -webkit-transform: translateZ(0) !important;
  -webkit-backface-visibility: hidden !important;

  margin: 0;
  color: var(--color-neutral-theme-400);
  background-color: var(--color-neutral-theme-50);

  font-family: Inter,
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              'Roboto',
              'Oxygen',
              'Ubuntu',
              'Cantarell',
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              sans-serif;
}

pre {
  margin: 0;
  white-space: break-spaces;
  font-family: Inter,
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              'Roboto',
              'Oxygen',
              'Ubuntu',
              'Cantarell',
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              sans-serif;
}

code {
  font-family: source-code-pro, 'Dosis', Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: var(--color-cyan-700);
  text-decoration: none;
}

p {
  color: var(--color-neutral-theme-900);
}

h1, h2, h3, h4, h5 {
  color: var(--color-neutral-theme-700);
}