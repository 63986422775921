@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  background-color: $color-neutral-theme-50;
  border-radius: 32px;
  max-width: 637px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 18px;
  max-width: 301px;
}

.leftText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.right {
  width: 240px;
  @include aspect(240, 426);
}

.rightEmpty {
  width: 100%;
  display: flex;
  position: relative;
  padding: 133.555px 25px 136.101px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;

  border-radius: 12px;
  border: 1px dashed $color-neutral-theme-250;
}

.rightWithVideo {
  height: 100%;
  background-color: black;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.video {
  width: 100%;
  @include aspect(656, 369);
}

.videoButtons {
  position: absolute;
  bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36,37,45, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  border-radius: 18px;
}