@import '/src/styles/mixin';
@import '/src/styles/variables';

.content {
  width: calc(100vw - 132px);
  max-width: 600px;
}

.textarea {
  outline: none;
  border: 0;
  resize: none;
  transition: all $transition-duration ease-in-out;
  font-family: $primaryFont;

  &:hover {
    @include border($neutral-200);
  }

  &:focus {
    @include border($neutral-300);
  }

  &::placeholder {
    color: $neutral-300;
    font-size: 16px;
  }
}

.errorMsg {
  color: $negative-500;
  font-size: 12px;
  line-height: 20px;
}

.resetBtn {
  float: right;
}