.arrowLeft {
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
}

.arrowBottom {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.arrowTop {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}