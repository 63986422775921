@import '/src/styles/mixin';
@import '/src/styles/variables';

.STForm {
  width: 556px;
  display: flex;
  flex-direction: column;

  padding: 48px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }

  .LoginHeader {
    display: flex;
    flex-direction: column;
    padding: 18px 24px 24px 0;

    .Title {
      color: $neutral-700;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .Box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 460px;

    button[type='submit'] {
      width: 160px;
      align-self: flex-end;
      text-transform: capitalize;
    }
  }
}
