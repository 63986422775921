.container {
  position: relative;
  max-width: 408px;
  height: 100%;
  padding: 32px 16px;
  border-radius: 20px;
  background-color: var(--color-neutral-theme-50);

  gap: 32px;
  display: flex;
  flex-direction: column;

  width: 408px;
}

.top {
  gap: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    text-align: center;
  }
}
