@import '/src/styles/variables';
@import '/src/styles/mixin';

.talentDetail {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 100px
}

.videoContainer {
  max-width: 403px;
  width: 100%;
}

.contactUser {
  width: 600px;
  border: 1px solid $grey-02;
  border-radius: 12px;
  padding: 24px;
}