@import '/src/styles/mixin';
@import '/src/styles/variables';

.campaignContainer {
  max-width: 335px;
  width: 100%;
  background: var(--color-neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}