@import '/src/styles/mixin';
@import '/src/styles/variables';

.content {
  width: calc(100vw - 132px);
  max-width: 400px;
}

.input {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 10px;
  width: 100%;

  @include transition(box-shadow $transition-duration ease-in-out);
  @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  &:hover {
    @include box-shadow(inset 0px 0px 0px 1px $neutral-50);
  }
  &.focused {
    @include box-shadow(inset 0px 0px 0px 2px $green-75);
  }
  &.error {
    @include box-shadow(inset 0px 0px 0px 1px $negative-500);
  }
  &.disabled {
    cursor: not-allowed;
    color: $neutral-40;
    background-color: $neutral-10;
    @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  }
}

.errorMsg {
  color: $negative-500;
  font-size: 12px;
  line-height: 20px;
}