// @mixin trackStyles {
//   height: 100%;
//   border-radius: 4px;
//   background: var(--color-neutral-theme-300);
// }

@mixin thumbStyles {
  width: 12px;
  height: 12px;
  // margin-top: -4px; // use with trackStyles
  background: white;
  border-radius: 100%;
}

.PlayerRange {
  width: 100%;
  height: 6px;
  border-radius: 4px;
  margin: 0 8px;
  display: flex;
  align-self: center;

  cursor: pointer;

  background: #80818E;
  background-repeat: no-repeat;
  background-image: linear-gradient(#F7FB6F, #F7FB6F);
  // background-size: 0% 100%;

  // transition: background-size 1000ms linear;
  // -o-transition: background-size 1000ms linear;
  // -ms-transition: background-size 1000ms linear;
  // -moz-transition: background-size 1000ms linear;
  // -webkit-transition: background-size 1000ms linear;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  // &::-webkit-slider-runnable-track {
  //   -webkit-appearance: none;
  //   @include trackStyles;
  // }
  // &::-moz-range-track {
  //   -moz-appearance: none;
  //   @include trackStyles;
  // }
  // &::-ms-track {
  //   appearance: none;
  //   @include trackStyles;
  // }

  &.Mini{
    height: 3px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumbStyles;
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    @include thumbStyles;
  }
  &::-ms-thumb {
    appearance: none;
    @include thumbStyles;
  }
}
