@import '/src/styles/variables';

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  min-width: 120px;
}