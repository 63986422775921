.base {
  margin: 0;
  border: none;
}

.horizontal {
  border-right: 1px solid;
  height: 100%;
}

.vertical {
  border-bottom: 1px solid;
}