@import '/src/styles/mixin';
@import '/src/styles/variables';

.likesDetailScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 100%;

  padding: 16px;
  background-color: var(--color-neutral-theme-100);

  @media screen and (max-width: 1275px) {
    min-width: 1000px;
  }

  @media screen and (max-height: 862px) {
    min-height: 720px
  }

  @include tabletAndMobile() {
    min-width: unset;
    min-height: unset;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    border-top: 1px solid rgb(218, 219, 231);
  }
}

.candidateDetail {
  display: flex;
  /*@include mobile() {
    flex-direction: column;
  }*/

  overflow: hidden;
  aspect-ratio: 1076 / 826;
  border-radius: 16px;
  background-color: var(--color-white);

  min-width: 1000px;

  @media screen and (max-width: 1360px) {
    min-width: 900px
  }

  @include tabletAndMobile() {
    width: 100%;
  }

  .tabSpacing {
    padding-top: 12px;
    padding-bottom: 12px;

    @media screen and (max-width: 1360px) {
      gap: 8px;
    }
  }

  @include tabletAndMobile() {
    min-width: unset;
    min-height: unset;
    aspect-ratio: unset;
    height: 100%;
  }

  @include onlyMobile() {
    border-radius: 0;
    height: unset;
    padding-bottom: 16px;

    flex-direction: column;
    overflow: auto;
  }

  .infoContainer {
    flex: 1 1;
    @include tablet() {
      max-width: 50%;
      overflow: hidden;
      flex: unset;
    }

    @include onlyMobile() {
      max-width: unset;
    }

    &_showingComment {
      @include tablet() {
        width: 50%;
      }

      @include onlyMobile() {
        width: 100%;
      }
    }
  }

  .mobileDivider {
    height: 8px;
    width: 100%;
    content: '';
    display: inline-block;
    padding-top: 10px;
    background-color: $color-neutral-theme-100;
  }

  .videoContainer {
    border-right: 1px solid rgb(218, 219, 231);
    aspect-ratio: 432 / 826;
    height: 100%;
    width: auto;

    @include tabletAndMobile() {
      aspect-ratio: unset;
      flex: 1 1;
    }

    @include onlyMobile() {
      height: unset;
      padding: 0 0 16px 0 !important;
    }
  }

  .btnIntroduce {
    @include onlyMobile() {
      margin: 0 16px;
    }
  }

  .videoPlayer {
    width: 100%;
    height: 100%;

    background-color: black;

    @include tabletAndMobile() {
      @include aspect(322, 570);
      min-width: 200px;
      width: 100%;
      height: auto;
      border-right: none;
    }

    @include onlyMobile() {
      border-radius: 0 !important;
    }
  }

  .isSuperNova {
    background-image: url('/images/bg-supernova.png');
    background-size: cover;
  }

  .isSuperNovaHeader {
    background-image: url('/images/bg-supernova-header.png');
    background-size: cover;
  }
}

.LabelText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Inter';
  color: $color-neutral-theme-300;
  margin: 0;
}

.Accordion {
  box-shadow: none !important;
}

.AccordionSummary {
  padding: 0 !important;
}

.AccordionDetails {
  padding: 0 !important;
}

.Line{
  width: 100%;
  height: 1px;
  background-color: $color-neutral-theme-200;
}

.ALink {
  // color: $color-neutral-theme-700;
  font-size: 14px;
}

.FullNameTitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  font-family: 'Inter';
  color: $color-neutral-theme-700;
  margin: 0;
  margin-bottom: 8px;
}

.LabelMethod {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Inter';
  color: $color-neutral-theme-300;
  margin: 0;
  margin-bottom: 8px;
}

.STPersonal {
  word-break: break-word;

  display: flex;
  flex-direction: column;
  gap: 8px;

  div {
    color: $color-neutral-theme-700;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}


.STIntroduceDate {
  padding: 0 24px 16px;
  margin-top: 16px;
}
