@import '/src/styles/mixin';
@import '/src/styles/variables';

.motivatorBadge {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  padding: 6px 10px;
  border-radius: 50px;
}
.Altruistic {
  color: #754648;
  background-color: #F8D4D4;
}
.Competitive {
  color: #606137;
  background-color: #ECEE9D;
}
.Creative {
  color: #834887;
  background-color: #F6D1F0;
}
.Economic {
  color: #1E6065;
  background-color: #AEEBF0;
}
.Individualistic {
  color: #E56C09;
  background-color: #FFEFD5;
}
.Learner {
  color: #BD7A5E;
  background-color: #FFEAD3;
}
.Mission-Driven {
  color: #3B5986;
  background-color: #D5E3FA
}