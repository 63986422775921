@import '/src/styles/variables';

.button {
  color: $color-white !important;
  background-color: $color-neutral-theme-50 !important;
  border: 1px solid $color-neutral-theme-200;
  min-width: unset !important;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 12px;

  &:hover {
    background-color: $color-neutral-theme-50 !important;
    border: 1px solid $color-neutral-theme-700;
  }

  &:disabled {
    opacity: 0.5;
  }
}