@import '/src/styles/mixin';
@import '/src/styles/variables';

.talentBlurcontainer {
  position: relative;
  max-width: 335px;
  width: 100%;
  background: $neutral-20;
}

.talentBlurWrapper {
  padding: 16px;
  background: var(--color-neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  filter: blur(15px);
  pointer-events: none;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.viewMore {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}