@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;

  flex-direction: column;
  display: flex;
  transition: none;
}

.header {
  display: none;
  width: 100%;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 16px 44px;
  background-color: $color-neutral-theme-50;
  border-bottom: 1px solid $color-neutral-theme-100;

  @include tabletAndMobile() {
    display: flex;
  }
}

.buttonBack {
  position: absolute !important;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $color-neutral-theme-100 !important;
  width: 44px;
  height: 44px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  padding: 90px 24px 40px;
  background-color: $color-neutral-theme-50;

  @include tabletAndMobile() {
    padding: 16px;
  }
}

.close {
  position: absolute !important;
  right: -20px;
  top: -10px;
  z-index: 2;
  width: 40px;
  height: 40px;
  box-shadow: 0px 8px 16px rgba(37, 38, 46, 0.2);
  cursor: pointer;
  border-radius: 32px;
  background-color: #FFFFFF !important;

  &:hover {
    background-color: $color-neutral-theme-100;
  }

  @include tabletAndMobile() {
    display: none !important;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.jobSection {
  display: flex;
  flex-direction: row;
  gap: 61px;

  @include tabletAndMobile() {
    flex-direction: column;
    gap: 32px;
  }
}

.jobInfo {
  max-width: 587px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer {
  width: 100%;
  padding: 28px 44px 18px;
  display: flex;
  gap: 14px;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid $color-neutral-theme-200;
  background-color: $color-neutral-theme-50;
}

.Avatar {
  border-radius: 12px;
  overflow: hidden;
  background-color: $color-neutral-theme-100;
  @include avatar(80px, 80px);
}