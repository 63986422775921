@import '/src/styles/mixin';
@import '/src/styles/variables';

.forgotPasswordSuccess {
  width: 464px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  margin-left: auto;
  margin-right: auto;
}

.mainText {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.64px;
  text-transform: capitalize;
}

.subText {
  text-align: left;
  @include onlyMobile() {
    padding: 0 8px;
  }
}

.Email {
  color: $color-neutral-theme-800;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.resendEmail {
  @include onlyMobile() {
    text-align: center;
  }
}
