.container {
  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0)
    }

    100% {
      opacity: 1;
      transform: scale(1)
    }
  }

  position: relative;
  width: 416px;
  padding: 80px 40px;
  border-radius: 32px;
  background-color: var(--color-neutral-theme-50);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  img {
    animation: show 1s;
  }
}