@import '/src/styles/mixin';
@import '/src/styles/variables';

.spinner {
  animation: var(--spin-animation, spin) 1s linear infinite;
}

:export {
  neutralWhite: $neutral-white;
  primary: $color-primary-600;
}