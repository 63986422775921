$line-values: ();

@for $i from 1 through 5 {
    $line-values: append($line-values, $i);
}

.three-dot {
    @each $size in $line-values {
        &-#{$size} {
            display: -webkit-box;
            -webkit-line-clamp: #{$size};
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }
    }
}