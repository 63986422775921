@import '/src/styles/mixin';
@import '/src/styles/variables';

.VideoPlayerCtn {
  border-radius: 8px;
  position: relative;
}

.VideoPlayerSlide {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  transition: all 1s;
  z-index: 1;
}

.VideoPlayerSlideNext {
  top: 0;
  left: 0;
  transform: translate(125%, 0) scale(0.7);
  pointer-events: none;
}

.NextBtn {
  position: absolute;
  outline: none;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 100%;
  transform: translate(16px, -50%);
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  svg {
    transform: scale(1.33)
  }

  @include mediaQueryMax(521px) {
    transform: translate(-8px, -50%);
    z-index: 2;
  }
}

.VideoPlayerSlidePrev {
  top: 0;
  left: 0;
  transform: translate(-125%, 0) scale(0.7);
  pointer-events: none;
}

.PrevBtn {
  outline: none;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(-16px, -50%);
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  svg {
    transform: scale(1.33)
  }

  @include mediaQueryMax(521px) {
    transform: translate(8px, -50%);
    z-index: 2;
  }
}

.NoShow {
  visibility: collapse;
  opacity: 0;
}

.Overlay {
  background-color: rgba(24, 25, 32, 0.85);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.videoPlayerOverride {
  :global {
    video::-webkit-media-text-track-display {
      padding: 0 16px !important;
    }
  }
}