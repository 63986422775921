@import '/src/styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 150px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $black_01;

  &::after {
    content: '*';
    color: $color-negative-500;
  }
}