@import '/src/styles/mixin';
@import '/src/styles/variables';

.container {
  border-radius: 12px;

  @include aspect(257, 458);
  width: 100%;
  position: relative;

  background-position: center !important;
  background-size: cover !important;
}

.iconPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  position: absolute !important;
  border-radius: 100% !important;
  background-color: rgba(24, 25, 32, 0.7) !important;
}

.iconLike {
  right: 8px !important;
  bottom: 118px !important;
}

.iconDocument {
  right: 8px !important;
  bottom: 70px !important;
}

.name {
  position: absolute;
  height: 56px;
  bottom: 0;
  left: 0;
  padding: 8px 8px 12px 8px;
}