@import '/src/styles/variables';

.container {
  padding: 32px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;

  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;

  max-width: 421px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}