.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.1);
  width: 100%;
  aspect-ratio: 343/601;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  gap: 9px;
}