@import '/src/styles/mixin';
@import '/src/styles/variables';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  border: unset;
  background: linear-gradient(90deg, #7EF2A7 0%, #9EF291 24.5%, #C2F213 59%, #CCF241 75%, #D4D94A 100%);
}


.ff-primary {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}