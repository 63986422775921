@import '/src/styles/mixin';
@import '/src/styles/variables';

$containerBorderRadius: 32px;

.container {
  background-color: $color-neutral-theme-50;
  width: 683px;
  border-radius: $containerBorderRadius;

  height: 100%;

  @include tabletAndMobile() {
    width: 100%;
    max-width: 683px;
  }

  padding: 32px 0;
}

.content {
  padding: 0 48px;
  position: relative;
  height: 100%;
  overflow-y: auto;

  @include onlyMobile() {
    padding: 0 16px;
  }
}

.loadingContainer {
  padding: 32px 48px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: $containerBorderRadius;
}

.Avatar {
  @include avatar(80px, 80px);
}

.briefDescription {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
   h6 {
    font-size: 0.67em;
  }
}
