@import '/src/styles/mixin';
@import '/src/styles/variables';

.vccContainer {
  max-width: 269px;
  width: 100%;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vvcInfo {
  background-color: $neutral-white;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px #0000000D;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.menu.menuOverride {
  [class*='MuiPaper-root'] {
    width: 200px;
    padding: 8px 0px;
    margin-top: 8px;
    border-radius: 12px;
    border: 1px solid $neutral-white-02;
    color: $black-01;
  }
}

.menuItem.menuItemOverride {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 12px;

  &:hover {
    background-color: $green-500;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
