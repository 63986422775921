@import '/src/styles/variables';

.smallLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  max-width: 197px;
}

.loader {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  box-shadow: 0 0 0 5px inset $color-neutral-theme-200;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid $color-primary-400;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   { clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0) }
  25%  { clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0) }
  50%  { clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%) }
  75%  { clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%) }
  100% { clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0) }
}