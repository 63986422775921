@import '/src/styles/mixin';
@import '/src/styles/variables';

.textarea {
  border: 0;
  outline: none;
  // transition: all $transition-duration ease-in-out;
  width: min(18vw, 250px);
  height: 168px;

  resize: vertical;
  color: $neutral-white;
  background-color: $neutral-750;

  @include border($neutral-200);

  &:hover {
    @include border($neutral-300);
  }

  &:focus {
    @include border($neutral-400);
  }

  &::placeholder {
    color: $neutral-270;
  }

  @include mobile() {
    position: fixed;
    width: 100vw;
    box-shadow: unset;
    top: -16px;
    right: -16px;
  }
}