@import '/src/styles/variables';

.container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.toggleSwitch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $green-600;
    }

    &:checked + .slider:before {
      transform: translateX(20px);
    }
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  border-radius: 14px;
  background-color: #ccc;
  transition: background-color 0.3s ease;

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    left: 2px;
    top: 1px;
    transition: transform 0.3s ease;
  }
}


.label {
  cursor: pointer;
}