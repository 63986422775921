@import '/src/styles/mixin';
@import '/src/styles/variables';

.groupRadio {
  display: flex;
  gap: 24px;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: $black-01;
}

.radioInput {
  margin: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 0;
}

input[type='radio'] {
    accent-color: $black-01;
}